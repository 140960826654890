import { Center, Image, Progress } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Api from '../Api/Api';
import logo from '../assets/logonew.png'
import Home from './Home';
import HomeCliente from './HomeCliente';
function Carregando() {

const [role, setRole] = useState("");
useEffect(() => {
    Api.get("Home/RoleUserLogado")
        .then(res => {
            setRole(res.data)
            localStorage.setItem("@HSCHOOL_ROLE", res.data)
        })
        .catch(err => {

        })
}, [role])


    return (
        <>
            {
                role === "" ? (
                    <Center flexDirection="column" h="100vh">
                        <div>
                            <Image src={logo} w="200px" mb="4" />
                        </div>
                        <div>
                            <Progress
                                size="xs"
                                isIndeterminate
                                w="260px"
                                colorScheme="gray"
                            />
                        </div>
                    </Center>
                ) : (role === "Colaborador" ? (
                    <HomeCliente />
                ) : (
                    <Home />
                ))
            }
        </>
    );
}

export default Carregando;