import React from 'react';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import InputForm from '../Input/InputForm/Index';
import CModal from '../CModal/Index'
import { Button, HStack, Stack, useToast } from '@chakra-ui/react';
import Api from '../../Api/Api';
import InputIES from '../Input/InputForm/InputIES';
import InputCampus from '../Input/InputForm/InputCampus';
import InputformSelect from '../Input/InputForm/InputFormSelect';
// import { validate } from 'gerador-validador-cpf'
const chrema = yup.object({
    nome: yup.string().matches(/^.*[ ].[a-zA-Z]*$/g, "Digite o seu nome completo."),
    email: yup.string().email("Email inválido"),
    cpf: yup.string().matches(/[0-9]{11}/g, "Formato de cpf inválido").min(11, "Formato de CPF inválido"),
    senha: yup.string(),
    confirmarSenha: yup.string().oneOf([yup.ref('senha')], 'Senha e confirmar senha não coincidem.'),
    nucleo: yup.string(),
    unidade: yup.string(),
    telefone: yup.string().matches(/\([0-9]{2}\)\s[0-9]{5}-[0-9]{4}/g, { message: "Telefone inválido." }).min(11, "CPF inválido"),
    regional: yup.string(),
}).required("Campo obrigatório.")

function ModalCriarUsuario({ open, onClose, updateLista }) {
    const { handleSubmit, register, formState: { errors, isValid }, watch, resetField, setError, } = useForm({
        resolver: yupResolver(chrema)
    })
    const toast = useToast();

    function submit(data) {

        const formatData = {
            nomeCompleto: data.nome,
            email: data.email,
            cpf: data.cpf,
            password: data.senha,
            repeatPassword: data.confirmarSenha,
            telefone: data.telefone,
            unidade: data.unidade,
            nucleo: data.nucleo,
            regional: data.regional
        }

        Api.post("Auth/RegisterUser", formatData)
            .then(res => {
                toast({
                    title: "Usuário criado com sucesso!",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                onClose()
                updateLista();
            })
            .catch(err => {
                console.log({ "Auth/SignUp": err.response.data });
                if (err.response.data === "USER_ALREADY_EXISTS")
                    setError("email", { message: "Usuário com esse email já existe." })
                if (err.response.data === "USER_NOT_ALLOWED")
                    toast({
                        title: "Falha ao criar usuário",
                        status: 'warning',
                        duration: 5000,
                        description: "Apenas administradores podem criar usuários no sistema."
                    })
            })
    }


    return (
        <CModal
            title="Cadastrar novo usuário"
            open={open}
            onClose={onClose}
            footer={
                <></>
            }
        >
            <form onSubmit={handleSubmit(submit)}>
                <Stack spacing={4}>

                    <InputForm register={register("nome")} errors={errors.nome}
                        name="nome" title='Nome completo' required />
                    <InputForm register={register("email")} errors={errors.email}
                        name="email" title='Email' required />
                    <InputForm register={register("cpf")} inputMask="99999999999" errors={errors.cpf}
                        name="cpf" title='CPF' required />
                    <InputForm register={register("senha")} errors={errors.senha}
                        name="senha" title='Senha' required />
                    <InputForm register={register("confirmarSenha")} errors={errors.confirmarSenha}
                        name="confirmarSenha" title='Confirmar senha' required />
                    <InputIES register={register("nucleo")} errors={errors.nucleo}
                        name="nucleo" title='Núcleo' required />
                    <InputCampus register={register("unidade")}
                        marca={watch("nucleo", false)} errors={errors.unidade}
                        reset={resetField} name="unidade" title='Unidade' required />
                    <InputForm register={register("telefone")} inputMask="(99) 99999-9999" errors={errors.telefone}
                        name="telefone" title='Telefone' required />

                    <InputformSelect register={register("regional")} errors={errors.regional}
                        name="regional" placeholder='Regional...' title="Regional" typeList="Regional"
                    />

                    <HStack spacing={4} justifyContent="flex-end">
                        <Button onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button type='submit'>
                            Cadastrar Usuário
                        </Button>
                    </HStack>
                </Stack>
            </form>
        </CModal>
    );
}

export default ModalCriarUsuario;