import React from 'react';
import Botao from '../Botao/Index';
import CModal from '../CModal/Index';

function ModalErro({
    open,
    errorList,
    onClose
}) {

    return (
        <CModal
            title="Erros encontrados"
            open={open}
            footer={
                <div>
                    <Botao
                        text="Fechar"
                        onClick={onClose}
                        tema="button_style"
                    />
                </div>
            }
        >
            <p className='pb-3'>Formulários não foram salvos porque não foi possível encontrar certos dados, preenche-os adequadamente e em seguida tente novamente.</p>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Linha</th>
                        <th scope="col">Campos em branco ou inválidos</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        errorList.map((r, index) => (
                            <tr>
                                <th scope="row">{r.linha}</th>
                                <td>{r.tipos.join(', ')}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>

        </CModal>
    );
}

export default ModalErro;