import React, { useEffect, useState } from "react";
import AuthApi from "../Api/AuthApi";
import { useHistory } from "react-router-dom";
import Api, { baseUrl } from "../Api/Api";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ImportarFormulario from "../components/ImportarFormulario/Index";
import ExportarModelo from "../components/Exports/ExportarModelo";
import { Box, Button, Container, Flex, Grid, GridItem, Heading, HStack, Text, useTheme } from "@chakra-ui/react";
import Card from "../components/Card/Card";
import { AiOutlineQrcode } from 'react-icons/ai'
import GetRole from "../service/GetRole";

function Home() {
  document.title = "Principal"
  const [list, setList] = useState([]);
  const [listRank, setListRank] = useState([]);
  const history = useHistory();
  const [totais, setTotais] = useState();
  const [graficoLeads, setGraficoLeads] = useState();
  const [graficouser, setGraficouser] = useState();
  const [loadCSV, setLoadCSV] = useState(false);
  const theme = useTheme()
  useEffect(() => {
    AuthApi.listForm({
      result: (e) => {
        setList(e);
        setLoadCSV(true);
      },
      error: () => { },
    });
  }, []);

  useEffect(() => {
    const role = localStorage.getItem("@HSCHOOL_ROLE");
    if (role === "Colaborador") {
      history.replace("/login")
      localStorage.clear()
    }
  }, [history])

  useEffect(() => {
    AuthApi.listRanking({
      result: (e) => {
        setListRank(e);
      },
      error: () => { },
    });
  }, []);


function downloadFormularios() {
  window.open(baseUrl + "Formulario/Download", "_blank");
}


  function totaisApi() {
    Api.get("/dashboard/Totais").then((res) => {
      setTotais(res.data);
    });
  }

  function graficoLeadApi() {
    Api.get("/Dashboard/Leads").then((res) => {
      setGraficoLeads(res.data);
    });
  }
  function graficoUserApi() {
    Api.get("/Dashboard/Usuarios").then((res) => {
      setGraficouser(res.data);
    });
  }

  useEffect(() => {
    totaisApi();
    graficoLeadApi();
    graficoUserApi();
  }, []);


  return (
    <>
      <Box
        w="100%"
        h="100vh"
        position="fixed"
        top="0"
        left="0"
        zIndex="-1"
      />

      <div className=" bg py-5">
        <Container maxW="5xl" py="4">
          <Grid
            templateColumns="2fr 1fr"
            gridGap="20px"
          >
            <GridItem>

              <Grid
                templateColumns="1fr"
                templateRows="auto auto auto"
                gridGap="20px"
              >
                <GridItem rowStart={1} >
                  <HStack spacing={2} mb="4">
                    {
                      !loadCSV && (
                        <Button variant="dark" isDisabled size="sm">
                          Carregando CSV...
                        </Button>
                      )
                    }
                    {
                      loadCSV && (
                        <Button onClick={() => downloadFormularios()}>
                          Exportar arquivo CSV
                        </Button>
                      )
                    }
                    <ImportarFormulario />
                    <ExportarModelo />
                    <Button variant="gray" size="sm" onClick={() => history.push("leads")}>
                      Listar leads
                    </Button>
                    <Button
                      onClick={() => {
                        history.replace("Login");
                        localStorage.clear();
                      }}
                      size="sm"
                    >
                      Deslogar
                    </Button>
                  </HStack>
                  {
                    GetRole() === "Administrador" && (
                      <HStack spacing={2} mb="4">
                        <Button variant="gray" size="sm" onClick={() => history.push('usuarios')}>
                          Listar usuários
                        </Button>
                        <Button variant="dark" size="sm" onClick={() => history.push("listas")}>
                          Listas suspensas
                        </Button>
                      </HStack>
                    )
                  }
                  <Card>
                    <Heading size="sm" textAlign="center">Leads</Heading>
                    <Bar
                      plugins={{
                        datalabels: {
                          display: true,
                          color: "#000",
                        },
                      }}
                      data={{
                        labels: graficoLeads?.map((e) => e.regional),
                        datasets: [
                          {
                            label: "",
                            data: graficoLeads?.map((e) => e.total),
                            backgroundColor: ["#4F6170"],
                          },
                        ],
                      }}
                    />
                  </Card>
                </GridItem>

                <GridItem rowStart={2} >
                  <Flex>
                    <Flex flex="2" mr="2">
                      <Card>
                        <div>
                          <div className=" card p-2  h-100 w-100  d-inline-block">
                            <Heading size="sm" textAlign="center">Usuários</Heading>
                            <Bar
                              data={{
                                labels: graficouser?.map((e) => e.regional),
                                datasets: [
                                  {
                                    label: "",
                                    data: graficouser?.map((e) => e.total),
                                    backgroundColor: ["#4F6170"],
                                  },
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </Card>
                    </Flex>
                    <Flex flex="1" ml="2">
                      <Card>
                        <Box textAlign="center">
                          <Text fontSize="1.3rem" fontWeight="bold">Total de Leads</Text>
                          <Text fontSize="1.3rem" fontWeight="medium" pb="4">{totais?.leads}</Text>
                          <Text fontSize="1.3rem" fontWeight="bold">Total de Usuários</Text>
                          <Text fontSize="1.3rem" fontWeight="medium">{totais?.usuarios}</Text>
                        </Box>
                      </Card>
                    </Flex>
                  </Flex>

                </GridItem>

                <GridItem rowStart={3}>
                  <Flex>
                    <Flex flex={2}>
                      <Card>
                        <Flex justifyContent="space-between" alignItems="center">
                          <AiOutlineQrcode size={'90px'} color={theme.colors.gray["60"]} />
                          <Button variant="dark" onClick={() => history.push("qrcode")}>
                            Ver Qr codes
                          </Button>
                        </Flex>

                      </Card>
                    </Flex>
                    <Flex flex={1}>
                    </Flex>
                  </Flex>
                </GridItem>
              </Grid>

            </GridItem>
            <GridItem>
              <GridItem colSpan={1} colStart={2} rowSpan={1} rowStart={1}>
                <Card>
                  <Text fontSize="1.4rem" fontWeight="bold" textAlign="center" mb="3" >Ranking</Text>
                  {listRank.map((e, index) => (
                    <Flex key={index} mx="3" mb="4">
                      <Text fontSize="1.8rem" fontWeight="medium" mr="4">
                        {listRank.indexOf(e) + 1}
                      </Text>
                      <div>
                        <div className="d-flex justify-content-between mb-2">
                          <Text fontSize="1.08rem" fontWeight="bold">{e.nome}</Text>
                        </div>
                        <Flex>
                          <p className="truncate-nowap">Formulários cadastrados:  </p>
                          <p className="ml-1">{e.totalFormularios}</p>
                        </Flex>

                        <div className="d-flex justify-content-between mb-2">
                          <p className="regiao-ranking">{e.regional}</p>
                        </div>
                      </div>
                    </Flex>
                  ))}
                </Card>
              </GridItem>
            </GridItem>
          </Grid>

        </Container>
      </div >

    </>
  );
}
export default Home;
