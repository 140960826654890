import styled from 'styled-components';

export const InputStyle = styled.input`
border: 1px solid #E0E0E0;
border-radius: 3px;
padding: 15px 0 15px 17px;
margin: 5px 0;
`;

export const Title = styled.p`
    color: #000;
    display: block;
    margin: 15px 0 5px;
    
`;
