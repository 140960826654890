import { extendTheme } from "@chakra-ui/react";

const defaultStyle = extendTheme({
    styles: {
        global: () => ({
            body: {
                backgroundColor: "#f8f8f8",
                boxSizing: "border-box",
                margin: 0,
                padding: 0,
                outline: "none",
            },
        }),
    },

    colors: {
        gray: {
            100: "#FFF",
            80: "#86A4BD",
            60: "#4F6170",
            40: "#363738"
        },

        global: {
            body: {
                padding: 0,
                margin: 0,
                boxSizing: "border-box",
            },
        },
    },

    components: {
        Button: {
            variants: {
                "gray": {
                    bg: "gray.60",
                    color: "gray.100",

                    _hover: {
                        bg: "gray.60"
                    },
                    _focus: {
                        bg: "gray.80"
                    },
                    _active: {
                        bg: "gray.80"
                    }
                },
                "dark": {
                    bg: "gray.40",
                    color: "gray.100",

                    _hover: {
                        bg: "gray.60"
                    },
                    _focus: {
                        bg: "gray.40"
                    },
                    _active: {
                        bg: "gray.40"
                    }
                },
                "gray-outline": {
                    bg: "transparent",
                    color: "gray.60",
                    borderWidth: "1px",
                    borderColor: "gray.60",
                    _hover: {
                        bg: "gray.60",
                        color: "gray.100"
                    },
                    _focus: {
                        bg: "gray.80"
                    },
                    _active: {
                        bg: "gray.80"
                    }
                }
            }
        }
    },
});

export default defaultStyle;
