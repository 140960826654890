import { Box, Center, Icon, Tag, MenuList, MenuItem, Menu, MenuButton, Spinner, Text, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { BiMenu } from 'react-icons/bi';
import Api from '../../Api/Api';
import moment from 'moment';
import PrintQrcode from '../PrintQrcode/Index';
import { useHistory } from 'react-router-dom';
import ViewQrcode from '../ViewQrcode/Index';
function ListaQRcodeCliente() {
    const [list, setList] = useState([]);
    const [loadList, setLoadList] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [viewSelect, setViewSelect] = useState(null);
    const history = useHistory();

    useEffect(() => {
        setLoadList(true)
        Api.get("Qrcode/Cliente/Listar")
        .then(res => {
                setLoadList(false)                
                setList(res.data)
            })
            .catch(err => {
                setLoadList(false)
                console.log({ 'Qrcode/Cliente/Listar': err.response?.data })
            })
    }, [])

    const columns = [
        {
            name: "IES",
            selector: (row) => row.ies,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            compact: true,
            cell: (row) => (
                <Tooltip label={row.ies} maxW="100vw">
                    <Text isTruncated>{row.ies}</Text>
                </Tooltip>
            )
        },
        {
            name: "Unidade",
            selector: (row) => row.unidade,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.unidade} maxW="100vw">
                    <Text isTruncated>{row.unidade}</Text>
                </Tooltip>
            )
        },
        {
            name: "Tipo processo",
            selector: (row) => row.tipoProcesso,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.tipoProcesso} maxW="100vw">
                    <Text isTruncated>{row.tipoProcesso}</Text>
                </Tooltip>
            )
        },
        {
            name: "Mensagem",
            selector: (row) => row.mensagem,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.mensagem} maxW="100vw">
                    <Text isTruncated>{row.mensagem}</Text>
                </Tooltip>
            )
        },
        {
            name: "Fonte",
            selector: (row) => row.fonte,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.fonte} maxW="100vw">
                    <Text isTruncated>{row.fonte}</Text>
                </Tooltip>
            )
        },
        {
            name: "Cidade",
            selector: (row) => row.cidade,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.cidade}>
                    <Text isTruncated>{row.cidade}</Text>
                </Tooltip>
            )
        },
        {
            name: "UF",
            selector: (row) => row.uf,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            width: "64px"
        },
        {
            name: "Colégio",
            selector: (row) => row.escola,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.escola} maxW="100vw">
                    <Text isTruncated>{row.escola}</Text>
                </Tooltip>
            )
        },
        {
            name: "Tipo Instituição",
            selector: (row) => row.tipoInstituicao,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            width: "100px"
        },
        {
            name: "Tipo Instituição",
            selector: (row) => row.avaliacaoAção,
            cell: (r) => r.avaliacaoAcao ? "Sim" : "Não",
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            width: "100px"
        },
        {
            name: "Email responsável",
            selector: (row) => row.emailResponsavel,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Tooltip label={row.emailResponsavel} maxW="100vw">
                    <Text isTruncated>{row.emailResponsavel}</Text>
                </Tooltip>
            )
        },
        {
            name: "Data Ação",
            selector: (row) => row.createdAt,
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm")
        },
        {
            name: "Status",
            width: "auto",
            selector: (row) => row.encerrado,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => row.encerrado !== null ?
                <Tag size="sm" variant="outline" w="100%" whiteSpace="nowrap" >Encerrado {moment(row.encerrado).fromNow()}</Tag> :
                <Tag size="sm" variant="solid">Ativo</Tag>
        },
        {
            name: "Ações",
            compact: true,
            conditionalCellStyles: [
                {
                    when: row => row.encerrado !== null,
                    style: { color: "#939393", backgroundColor: "#f8f8f8" }
                }
            ],
            cell: (row) => (
                <Box>
                    <Menu
                        strategy='fixed'
                        isLazy
                    >
                        <MenuButton>
                            <Icon as={BiMenu} w="24px" h="24px" />
                        </MenuButton>
                        <MenuList>
                            {/* <ViewQrcode item={row} /> */}
                            <MenuItem onClick={() => {
                                setViewSelect(row)
                                setOpenView(true)
                            }}>Ver</MenuItem>
                            <PrintQrcode item={row} />
                            <MenuItem onClick={() => history.push(`/qrcode/detalhes`, {
                                QRCodeId: row.id
                            })}>
                                Listar Leads
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            )
        }
    ]



    return (
        <>
        <DataTable
            columns={columns}
            data={list}
            pagination
            paginationComponentOptions={{
                rowsPerPageText: "Qr codes por página",
                rangeSeparatorText: "de"
            }}
            highlightOnHover
            progressComponent={
                <Center p="42px">
                    <Spinner
                        w="120px"
                        h="120px"
                        color="gray.40"
                        thickness='4px'
                    />
                </Center>
            }
            progressPending={loadList}
            noDataComponent={
                <Center p="8">
                    <Text>Nenhum qrcode encontrado.</Text>
                </Center>
            }
        />
            <ViewQrcode item={viewSelect} open={openView} onClose={() => { setOpenView(false); setViewSelect(null) }} />
        </>
    );
}

export default ListaQRcodeCliente;