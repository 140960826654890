import { Button, Center, Collapse, Container, Heading, HStack, Icon, Spinner, Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory, useLocation } from 'react-router-dom';
import Api, { baseUrl } from '../Api/Api';
import Card from '../components/Card/Card';
import moment from 'moment'
import { BsArrowRightShort, BsArrowDownShort, BsArrowLeftShort } from 'react-icons/bs'
// import setSobrenome from '../service/SetSobrenome';
// import { CSVLink } from 'react-csv';
import CModal from '../components/CModal/Index'
function DetalheQRcode() {
    const { state } = useLocation();
    const [list, setList] = useState([])
    const [detalhe, setDetalhe] = useState(null);
    const history = useHistory();
    const [loadForm, setLoadForm] = useState(false)
    const [openDetalhe, setOpenDetalhe] = useState(false);
    const [openEncerramento, setOpenEncerramento] = useState(false);
    const [loadEncerramento, setLoadEncerramento] = useState(false)
    const toast = useToast();
    useEffect(() => {
        if (state.QRCodeId) {
            setLoadForm(true)
            Api.get(`qrcode/ListarFormularios/${state.QRCodeId}`)
                .then(res => {
                    console.log({ res })
                    setList(res.data)
                    setLoadForm(false)
                })
                .catch(err => {
                    console.log({ [`qrcode/ListarFormularios/${state.QRCodeId}`]: err.response.data })
                    setLoadForm(false)
                })

            Api.get(`qrcode/${state.QRCodeId}`)
                .then(res => {
                    console.log({ "detalhe": res.data })
                    setDetalhe(res.data)
                })
                .catch(err => {
                    console.log({ [`qrcode/${state.QRCodeId}`]: err.response.data })
                })
        } else {
            history.replace("/qrcode");
        }
    }, [state.QRCodeId, history])


    // function formatToExport() {
    //     let newListDataExport = []

    //     list.forEach(e => {
    //         newListDataExport.push({
    //             codigoQRcode: detalhe?.id,
    //             responsavel: detalhe?.emailResponsavel,
    //             dataAcao: moment(detalhe?.dataAcao).format('DD/MM/YYYY HH:mm'),
    //             nome: e.nome,
    //             sobrenome: setSobrenome(e.nome),
    //             sexo: e.sexo,
    //             email: e.email,
    //             telefone: e.telefone,
    //             cpf: e.cpf,
    //             colegio: e.colegio,
    //             tipoInstituicao: e.tipoInstituicao,
    //             serie: e.serie,
    //             curso: e.curso,
    //             uf: detalhe?.uf,
    //             cidade: detalhe?.cidade,
    //             fonte: detalhe?.fonte,
    //             tipoProcesso: detalhe?.tipoProcesso,
    //             mensagem: detalhe?.mensagem,
    //             unidade: detalhe?.unidade,
    //             ies: detalhe?.ies,
    //             assunto: detalhe?.assunto,
    //             tipoCurso: detalhe?.tipoCurso,
    //             metodoCurso: detalhe?.metodoCurso,
    //             origemInteressado: detalhe?.origemInteressado,
    //             canalOrigem: detalhe?.canalOrigem,
    //             tipoOrigem: detalhe?.tipoOrigem,
    //             status: detalhe?.status
    //         })
    //     })
    //     console.log({ newListDataExport });
    //     return newListDataExport;
    // }

    const formsColumns = [
        {
            name: "Nome Completo",
            selector: (w) => w.nome,
            compact: true,
        },
        {
            name: "Email",
            selector: (w) => w.email,
            compact: true,
        },
        {
            name: "CPF",
            selector: (w) => w.cpf,
            compact: true,
        },
        {
            name: "Telefone",
            selector: (w) => w.telefone,
            compact: true,
        },
        {
            name: "Sexo",
            selector: (w) => w.sexo,
            compact: true,
        }
    ]

    function ExpandableFormulario({ data }) {
        console.log({ data })
        return (
            <>
                <Stack p="4" fontSize="0.84rem" spacing="8px">
                    {/* <Text>Código gerado: <Text as="span" fontWeight="bold">{data.codigoGerado}</Text></Text> */}
                    <Text>Colégio: <Text as="span" fontWeight="bold">{data.colegio}</Text></Text>
                    <Text>Tipo Instituição: <Text as="span" fontWeight="bold">{data.tipoInstituicao}</Text></Text>
                    <Text>Série: <Text as="span" fontWeight="bold">{data.serie}</Text></Text>
                    <Text>Nome do curso de interesse: <Text as="span" fontWeight="bold">{data.curso}</Text></Text>
                    {
                        data.avaliacaoAcao !== "" && (
                            <Text>Avaliação da ação: <Text as="span" fontWeight="bold">{data.avaliacaoAcao}</Text></Text>
                        )
                    }
                </Stack>
            </>
        )
    }

    // const columnExportarLeads = {
    //     data: formatToExport(),
    //     headers: [
    //         { label: "Responsável da Ação", key: "responsavel" },
    //         { label: "Código QRcode", key: "codigoQRcode" },
    //         { label: "Nome", key: "nome" },
    //         { label: "Sobrenome", key: "sobrenome" },
    //         { label: "Data Ação", key: "dataAcao" },
    //         { label: "Sexo", key: "sexo" },
    //         { label: "Email", key: "email" },
    //         { label: "CPF", key: "cpf" },
    //         { label: "Telefone", key: "telefone" },
    //         { label: "Colegio", key: "colegio" },
    //         { label: "Tipo Instituicao", key: "tipoInstituicao" },
    //         { label: "Série", key: "serie" },
    //         { label: "Curso", key: "curso" },
    //         { label: "UF", key: "uf" },
    //         { label: "Cidade", key: "cidade" },
    //         { label: "Fonte", key: "fonte" },
    //         { label: "Tipo Processo", key: "tipoProcesso" },
    //         { label: "Mensagem", key: "mensagem" },
    //         { label: "IES de interesse", key: "ies" },
    //         { label: "Campus/Unidade", key: "unidade" },
    //         { label: "Assunto", key: "assunto" },
    //         { label: "Tipo do Curso", key: "tipoCurso" },
    //         { label: "Método do Curso", key: "metodoCurso" },
    //         { label: "Origem do Interessado", key: "origemInteressado" },
    //         { label: "Canal de Origem", key: "canalOrigem" },
    //         { label: "Tipo de Origem", key: "tipoOrigem" },
    //         { label: "Status", key: "status" }
    //     ],
    //     filename: `QRCode leads ${state?.QRCodeId} .csv`,
    // }

    function encerrarQRCode() {
        setLoadEncerramento(true);
        Api.get(`qrcode/encerrarQRcode/${state?.QRCodeId}`)
            .then(res => {
                toast({
                    title: 'QRCode encerrado.',
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                setLoadEncerramento(false);
                history.replace("/qrcode");
                setOpenEncerramento(false)
            })
            .catch(err => {
                console.log({ [`qrcode/encerrarQRcode/${state.QRCodeId}`]: err.response.data })
                setLoadEncerramento(false);
            })
    }

    function downloadFormularios() {
        const token = localStorage.getItem("@HSCHOOL_TOKEN");
        window.open(baseUrl + `Formulario/Download/QRCode/${state.QRCodeId}/${token}`, "_blank");
    }

    return (
        <>
            <Container maxW={"8xl"} pt="80px" pb="4">
                <Button
                    variant="gray"
                    onClick={() => history.goBack()}
                    leftIcon={<Icon as={BsArrowLeftShort} />}
                    size="sm"
                >
                    Voltar
                </Button>
                <HStack justifyContent="space-between">
                    <Heading pb="6" pt="4">Detalhes Qrcode</Heading>
                    <HStack>
                        <Button
                            isDisabled={detalhe?.encerrado !== null}
                            variant="gray-outline"
                            onClick={() => setOpenEncerramento(true)}>
                            Encerrar QRCode
                        </Button>
                        {/* <CSVLink {...columnExportarLeads}>
                            <Button variant="dark">
                                Exportar leads
                            </Button>
                        </CSVLink> */}
                        <Button variant="dark" onClick={() => downloadFormularios()}>
                            Exportar leads
                        </Button>
                    </HStack>
                </HStack>

                <Button
                    variant="gray"
                    onClick={() => setOpenDetalhe(!openDetalhe)}
                    rightIcon={openDetalhe ? <Icon as={BsArrowDownShort} /> : <Icon as={BsArrowRightShort} />}
                    mb="4"
                >
                    Detalhes:
                </Button>
                <Collapse pb="4" bg="white" mb="6" in={openDetalhe}>
                    <Stack spacing={4} >
                        <Text>IES: <Text as="span" fontWeight="bold">{detalhe?.ies}</Text></Text>
                        <Text>Unidade: <Text as="span" fontWeight="bold">{detalhe?.unidade}</Text></Text>
                        <Text>Código QRCode: <Text as="span" fontWeight="bold">{detalhe?.id}</Text></Text>
                        <Text>Responsável: <Text as="span" fontWeight="bold">{detalhe?.emailResponsavel}</Text></Text>
                        <Text>Data Ação: <Text as="span" fontWeight="bold">{moment(detalhe?.dataAcao).format("DD/MM/YYYY HH:mm")}</Text></Text>
                        <Text>Colegio: <Text as="span" fontWeight="bold">{detalhe?.escola}</Text></Text>
                        <Text>Fonte: <Text as="span" fontWeight="bold">{detalhe?.fonte}</Text></Text>
                        <Text>Mensagem: <Text as="span" fontWeight="bold">{detalhe?.mensagem}</Text></Text>
                        <Text>TipoProcesso: <Text as="span" fontWeight="bold">{detalhe?.tipoProcesso}</Text></Text>
                        <Text>UF: <Text as="span" fontWeight="bold">{detalhe?.uf}</Text></Text>
                        <Text>Cidade: <Text as="span" fontWeight="bold">{detalhe?.cidade}</Text></Text>
                        <Text>Assunto: <Text as="span" fontWeight="bold">{detalhe?.assunto}</Text></Text>
                        <Text>Tipo Curso: <Text as="span" fontWeight="bold">{detalhe?.tipoCurso}</Text></Text>
                        <Text>Método Curso: <Text as="span" fontWeight="bold">{detalhe?.metodoCurso}</Text></Text>
                        <Text>Origem Interessado: <Text as="span" fontWeight="bold">{detalhe?.origemInteressado}</Text></Text>
                        <Text>Canal Origem: <Text as="span" fontWeight="bold">{detalhe?.canalOrigem}</Text></Text>
                        <Text>Tipo Origem: <Text as="span" fontWeight="bold">{detalhe?.tipoOrigem}</Text></Text>
                        <Text>Status: <Text as="span" fontWeight="bold">{detalhe?.status}</Text></Text>
                    </Stack>
                </Collapse>

                <HStack spacing={2} pb="2">
                    <Text fontSize='1.16rem' pb="1">Leads capturados:</Text>
                    <Text fontSize="1.4rem" fontWeight='bold' >{list?.length}</Text>
                </HStack>
                <Card>
                    <DataTable
                        columns={formsColumns}
                        data={list}
                        progressPending={loadForm}
                        expandableRows
                        noDataComponent={
                            <Center p="4">
                                <Text color="gray.500">Nenhum aluno foi registrado nesse QRcode.</Text>
                            </Center>
                        }
                        highlightOnHover
                        title="Lista de alunos"
                        expandableRowsComponent={ExpandableFormulario}
                        progressComponent={
                            <Center p="42px">
                                <Spinner
                                    w="120px"
                                    h="120px"
                                    color="gray.40"
                                    thickness='4px'
                                />
                            </Center>
                        }
                    />
                </Card>
            </Container>
            <CModal
                title="Encerrar QRCode"
                open={openEncerramento}
                onClose={() => setOpenEncerramento(false)}
                footer={
                    <HStack spacing={4}>
                        <Button
                            variant="gray-outline"
                            onClick={() => setOpenEncerramento(false)}>
                            Cancelar
                        </Button>
                        <Button
                            onClick={() => encerrarQRCode()}
                            variant="dark"
                            isLoading={loadEncerramento}
                        >
                            Encerrar QRCode
                        </Button>
                    </HStack>
                }
            >
                <>
                    <Text>Deseja mesmo encerrar essa ação?</Text>
                </>
            </CModal>
        </>
    );
}

export default DetalheQRcode;