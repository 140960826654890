import React, { useState } from 'react';
import { useHistory } from 'react-router';
import AuthApi from '../Api/AuthApi';
import Input from '../components/Input/Index';

function AtualizarUsuario() {
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [nucleo, setNucleo] = useState("");
    const [unidade, setUnidade] = useState("");
    const history = useHistory();

    function atualizar() {
        AuthApi.atualizarUsuario({
            email,
            senha,
            nucleo,
            unidade
        }, {
            result: () => {
                alert("Usuário editado com sucesso!")
            },
            error: () => {}
        })
    }

    return (
        <div className="vh-100 d-flex justify-content-center align-items-center bg">
            <div className="col-12 col-md-4">
                <h3 className="text-white">Atualizar conta</h3>

                <Input
                    onChange={(e) => setEmail(e.target.value)}
                    title="Email"
                    type="email"
                    value={email}
                />
                <Input
                    onChange={(e) => setSenha(e.target.value)}
                    title="Senha"
                    type="password"
                    value={senha}
                />

                <hr className="bg-white" />

                <Input
                    onChange={(e) => setNucleo(e.target.value)}
                    title="Nucleo"
                    type="text"
                    value={nucleo}
                />

                <Input
                    onChange={(e) => setUnidade(e.target.value)}
                    title="Unidade"
                    type="text"
                    value={unidade}
                />

                <div className="d-flex justify-content-between pt-2">
                    <button onClick={() => history.push('Home')}>
                        Voltar
                    </button>
                    <button onClick={() => atualizar()}>
                        Atualizar
                    </button>
                </div>


            </div>
        </div>
    );
}

export default AtualizarUsuario;