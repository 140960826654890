import { Button, Center, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FcExpired } from 'react-icons/fc'
function Encerrado() {
  return (
    <Center h="100vh" flexDirection="column" p="4">
        <Icon as={FcExpired} w="200px" h="200px" mb="4" />
        <Text textAlign="center" mb="4">Não foi possível carregar o cadastro devido o encerramento desse qrcode.</Text>
        <Button variant="gray-outline" onClick={() => window.close()}>
            Fechar
        </Button>
    </Center>
  );
}

export default Encerrado;