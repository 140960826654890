import { Button, HStack, ListItem, Text, UnorderedList, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Api from '../../Api/Api';
import CModal from '../CModal/Index';

function ModalRemoverQRcode({
    open,
    onClose,
    qrcodeItem,
    updateList
}) {
    const toast = useToast();
    const [load, setLoad] = useState(false);

    function Deletar() {
        setLoad(true)
        Api.get(`qrcode/Excluir/${qrcodeItem.id}`)
            .then(() => {
                setLoad(false)
                toast({
                    title: "Deletado com sucesso",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                onClose()
                updateList()
            })
            .catch(err => {
                setLoad(false)
                console.log({ [`qrcode/Excluir/${qrcodeItem.id}`]: err.response.data });
            })
    }


    return (
        <CModal
            open={open}
            onClose={onClose}
            title="Remover QRcode"
            footer={
                <HStack spacing={4}>
                    <Button onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button variant="gray" isLoading={load} onClick={() => Deletar()}>
                        Remover Qrcode
                    </Button>
                </HStack>
            }
        >
            <Text>
                Qrcode será declarado como deletado, o que significa que:
            </Text>
            <UnorderedList mb="4">
                <ListItem>Todas as leads também serão declaradas deletadas</ListItem>
                <ListItem>Não será mais possível novos cadastros de leads através desse qrcode</ListItem>
            </UnorderedList>
            <Text>
                Deseja deletar mesmo assim?
            </Text>
        </CModal>
    );
}

export default ModalRemoverQRcode;