import { Button, Container, Grid, GridItem, Flex, HStack, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import Api from '../Api/Api';
import Card from '../components/Card/Card';
import ExportarModelo from '../components/Exports/ExportarModelo';
import ImportarFormulario from '../components/ImportarFormulario/Index';
import ListaQRcodeCliente from '../components/ListaQRcodeCliente/Index';
import setSobrenome from '../service/SetSobrenome';
import { Bar } from "react-chartjs-2";
function HomeCliente() {
    document.title = "Plataforma de leads - HighSchool"
    const history = useHistory();
    const [graficoLeads, setGraficoLeads] = useState([]);
    const [list, setList] = useState([]);
    const [loadCSV, setLoadCSV] = useState(false);

    useEffect(() => {
        Api.get("dashboard/cliente/Leads")
            .then(res => {
                setGraficoLeads(res.data);
            })
            .catch(err => {
                console.log({ "dashboard/clienteLeads(catch)": err.response?.data })
            })
    }, [])

    useEffect(() => {
        Api.get("Formulario/Cliente")
            .then(res => {
                setList(res.data)
                setLoadCSV(true)
            })
            .catch(err => {
                console.log({ "Formulario/Cliente(catch)": err.response?.data })
            })
    }, [])

    function formForm() {
        let newForm = [];
        list.forEach((o) => {
            newForm.push({
                cpf: o.cpf,
                // lat: o.lat,
                // long: o.long,
                // dataNascimento: o.dataNascimento,
                // marca: o.marca,

                // Lado do usuário
                nome: o.nome.split(' ')[0],
                sobrenome: setSobrenome(o.nome),
                sexo: o.sexo,
                email: o.email,
                telefone: o.telefone,
                escola: o.escola,
                instituicao: o.instituicao,
                escolaridade: o.escolaridade,
                curso: o.curso,
                uf: o.estado,
                // estado: o.estado,
                cidade: o.cidade,
                fonte: o.fonte,
                tipoProcesso: o.tipoProcesso,
                mensagem: o.mensagem,
                unidadeCodigo: o.unidadeCodigo,
                // unidade: o.unidade,
                IESCodigo: o.iesCodigo,
                // IES: o.ies,
                assunto: o.assunto,

                tipoCurso: o.tipoCurso,
                metodoCurso: o.metodoCurso,
                origemInteressado: o.origemInteressado,
                canalOrigem: o.canalOrigem,
                tipoOrigem: o.tipoOrigem,
                status: o.status,
                aceitoEm: o.aceitoEm,
                // marca: o.marca,

                // Lado do Coletor

                // nomeUser: o.nomeUser,
                // telefoneUser: o.telefoneUser,
                emailUser: o.emailUser,
                // cpfUser: o.cpfUser,
                dataHora: o.dataHora,
                // regional: o.regional,
                // unidadeUser: o.unidadeUser,
                // iesUser: o.iesUser,

                // nucleo: o.nucleo,
                // imagem1: o.imagens[0],
                // imagem2: o.imagens[1],
                // imagem3: o.imagens[2],
                // imagem4: o.imagens[3],
                // imagem5: o.imagens[4],
            });
        });

        // console.log({ newForm });
        return newForm;
    }

    const csExport = {
        data: formForm(),
        headers: [
            // { label: "Imagem 1", key: "imagem1" },
            // { label: "Imagem 2", key: "imagem2" },
            // { label: "Imagem 3", key: "imagem3" },
            // { label: "Imagem 4", key: "imagem4" },
            // { label: "Imagem 5", key: "imagem5" },

            // Colaborador
            // { label: "Nome Colaborador", key: "nomeUser" },
            // { label: "Telefone Colaborador", key: "telefoneUser" },
            { label: "Email Colaborador", key: "emailUser" },
            // { label: "CPF Colaborador", key: "cpfUser" },
            // { label: "Data e Hora", key: "dataHora" },
            // { label: "Regional Colaborador", key: "regional" },
            // { label: "Unidade Colaborador", key: "unidadeUser" },
            // { label: "IES colaborador", key: "iesUser" },
            // { label: "Marca", key: "marca" },
            // Formulário

            { label: "Nome", key: "nome" },
            { label: "Sobrenome", key: "sobrenome" },
            { label: "DataHora", key: "aceitoEm" },
            { label: "Sexo", key: "sexo" },
            { label: "Email", key: "email" },
            { label: "CPF", key: "cpf" },
            { label: "Telefone", key: "telefone" },
            { label: "Colegio", key: "escola" },
            { label: "Tipo Instituicao", key: "instituicao" },
            { label: "Série", key: "escolaridade" },
            { label: "Cuso", key: "curso" },
            { label: "UF", key: "uf" },
            { label: "Cidade", key: "cidade" },
            { label: "Fonte", key: "fonte" },
            { label: "Tipo Processo", key: "tipoProcesso" },
            { label: "Mensagem", key: "mensagem" },
            { label: "Campus/Unidade", key: "unidadeCodigo" },
            { label: "IES de interesse", key: "IESCodigo" },
            { label: "Assunto", key: "assunto" },
            { label: "Tipo do Curso", key: "tipoCurso" },
            { label: "Método do Curso", key: "metodoCurso" },
            { label: "Origem do Interessado", key: "origemInteressado" },
            { label: "Canal de Origem", key: "canalOrigem" },
            { label: "Tipo de Origem", key: "tipoOrigem" },
            { label: "Status", key: "status" }
        ],
        filename: "Lista Formulário.csv",
    };

    return (
        <div className="bg py-5">
            <Container maxW="5xl" py="4">
                <HStack spacing={2} mb="4">
                    {loadCSV && (
                        <CSVLink {...csExport}>
                            <Button variant="dark" size="sm">
                                Exportar arquivo CSV
                            </Button>
                        </CSVLink>
                    )}
                    {
                        !loadCSV && (
                            <Button variant="dark" isDisabled size="sm">
                                Carregando CSV...
                            </Button>
                        )
                    }
                    <ImportarFormulario />
                    <ExportarModelo />
                    <Button variant="gray" size="sm" onClick={() => history.push("leads")}>
                        Listar leads
                    </Button>
                    <Button
                        onClick={() => {
                            history.replace("Login");
                            localStorage.clear();
                        }}
                        size="sm"
                    >
                        Deslogar
                    </Button>
                </HStack>
                <Grid
                    templateColumns="1fr 1fr"
                    gridGap="20px"
                    mb="4"
                >
                    <GridItem>
                        <Card>
                            <Heading size="sm" textAlign="center">Leads</Heading>
                            <Bar
                                plugins={{
                                    datalabels: {
                                        display: true,
                                        color: "#000",
                                    },
                                }}
                                data={{
                                    labels: graficoLeads?.map((e) => e.marca),
                                    datasets: [
                                        {
                                            label: "",
                                            data: graficoLeads?.map((e) => e.total),
                                            backgroundColor: ["#4F6170"],
                                        },
                                    ],
                                }}
                            />
                        </Card>
                    </GridItem>
                    <GridItem>
                    </GridItem>
                </Grid>
                <Card >
                    <Flex justifyContent="space-between" alignItems="center">
                        <Heading size="sm" textAlign="center">QR Codes</Heading>
                        <Button size="sm" variant="dark" onClick={() => history.push("/qrcode/criar")}>
                            Gerar novo qrcode
                        </Button>
                    </Flex>
                    <ListaQRcodeCliente />
                </Card>
            </Container>
        </div>
    );
}

export default HomeCliente;