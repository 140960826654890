import { FormControl, FormLabel, HStack, Input, Stack, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Api from '../../Api/Api';
import CModal from '../CModal/Index';

function ModalDetalheUsuarios({ usuarioId, open, onClose }) {
    const [detalhe, setDetalhe] = useState(null);

    useEffect(() => {
        if (open) {
            Api.get(`home/GetUsuario/${usuarioId}`)
                .then(res => {
                    console.log({ res })
                    setDetalhe(res.data)
                })
                .catch(err => {
                    console.log({ [`home/GetUsuario/${usuarioId}`]: err.response.data })
                })
        }
    }, [open, usuarioId])

    return (
        <CModal
            open={open}
            onClose={onClose}
            title="Detalhe do usuário"
        >
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel>Nome</FormLabel>
                    <Input isDisabled value={detalhe?.nomeCompleto} />
                </FormControl>
                <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input isDisabled value={detalhe?.email} />
                </FormControl>
                <FormControl>
                    <FormLabel>CPF</FormLabel>
                    <Input isDisabled value={detalhe?.cpf} />
                </FormControl>
                <FormControl>
                    <FormLabel>Marca</FormLabel>
                    <Input isDisabled value={detalhe?.nucleo} />
                </FormControl>
                <FormControl>
                <FormLabel>Unidade</FormLabel>
                    <Input isDisabled value={detalhe?.unidade} />
                </FormControl>
                <FormControl>
                    <FormLabel>Telefone</FormLabel>
                    <Input isDisabled value={detalhe?.telefone} />
                </FormControl>
                <FormControl>
                    <FormLabel>Regional</FormLabel>
                    <Input isDisabled value={detalhe?.regional} />
                </FormControl>
                <HStack spacing={4}>
                    <Button variant="gray-outline" onClick={onClose}>
                        Fechar
                    </Button>
                </HStack>
            </Stack>
        </CModal>
    );
}

export default ModalDetalheUsuarios;