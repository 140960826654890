import { Box, Button, Container, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Text, Tag, Tooltip, useToast, Spinner, Center } from '@chakra-ui/react';
import React, { useState, useCallback, useEffect } from 'react';
import { BiMenu } from 'react-icons/bi';
import Card from '../components/Card/Card';
import moment from 'moment'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Api from '../Api/Api';
import ModalRemoverFormulario from '../components/ModalRemoverFormulario/Index';
function ClienteFormularios() {
    document.title = "leads"
    const [list, setList] = useState([])
    const [load, setLoad] = useState(true)
    const [viewSelect, setViewSelect] = useState(null);
    const [openRemover, setOpenRemover] = useState(false);
    const [loadRestaurar, setLoadRestaurar] = useState(false);
    const history = useHistory();
    const toast = useToast();

    const listLeads = useCallback(() => {
        setLoad(true)
        Api.get('/formulario')
            .then(res => {
                setList(res.data);
                setLoad(false)
            })
            .catch(err => {
                console.log({ "/qrcode/list(catch)": err.response.data });
                setLoad(false)
            })
    }, [])


    useEffect(() => {
        listLeads()
    }, [listLeads])

    const condicionalCol = [
        {
            when: row => row.deletado !== null,
            style: { color: "#FC806F", backgroundColor: "#f8f8f8" }
        },
        {
            when: row => row.deletadoQRCode !== null,
            style: { color: "#8f8f8f", backgroundColor: "#f8f8f8" }
        }
    ]

    function Restaurar(formularioId) {
        setLoadRestaurar(true);
        Api.get(`formulario/RestaurarCliente/${formularioId}`)
            .then(() => {
                toast({
                    title: "Lead restaurada",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                setLoadRestaurar(false)
                listLeads()
            })
    }

    const columns = [
        {
            name: "Nome",
            selector: (row) => row.nome,
            conditionalCellStyles: condicionalCol,
            compact: true,
            cell: (row) => (
                <Tooltip label={row.nome} maxW="100vw">
                    <Text isTruncated>{row.nome}</Text>
                </Tooltip>
            )
        },
        {
            name: "Email",
            selector: (row) => row.email,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.email} maxW="100vw">
                    <Text isTruncated>{row.email}</Text>
                </Tooltip>
            )
        },
        {
            name: "CPF",
            selector: (row) => row.cpf,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.cpf} maxW="100vw">
                    <Text isTruncated>{row.cpf}</Text>
                </Tooltip>
            )
        },
        {
            name: "Telefone",
            selector: (row) => row.telefone,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.telefone} maxW="100vw">
                    <Text isTruncated>{row.telefone}</Text>
                </Tooltip>
            )
        },
        {
            name: "Escola",
            selector: (row) => row.escola,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.escola} maxW="100vw">
                    <Text isTruncated>{row.escola}</Text>
                </Tooltip>
            )
        },
        {
            name: "Escolaridade",
            selector: (row) => row.escolaridade,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.escolaridade} maxW="100vw">
                    <Text isTruncated>{row.escolaridade}</Text>
                </Tooltip>
            )
        },
        {
            name: "Instituição",
            selector: (row) => row.instituicao,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.instituicao} maxW="100vw">
                    <Text isTruncated>{row.instituicao}</Text>
                </Tooltip>
            )
        },
        {
            name: "Sexo",
            selector: (row) => row.sexo,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.sexo} maxW="100vw">
                    <Text isTruncated>{row.sexo}</Text>
                </Tooltip>
            )
        },
        {
            name: "Marca",
            selector: (row) => row.marca,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.marca} maxW="100vw">
                    <Text isTruncated>{row.marca}</Text>
                </Tooltip>
            )
        },
        {
            name: "Estado",
            selector: (row) => row.estado,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.estado} maxW="100vw">
                    <Text isTruncated>{row.estado}</Text>
                </Tooltip>
            )
        },
        {
            name: "Cidade",
            selector: (row) => row.cidade,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.cidade} maxW="100vw">
                    <Text isTruncated>{row.cidade}</Text>
                </Tooltip>
            )
        },
        {
            name: "Curso",
            selector: (row) => row.curso,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.curso} maxW="100vw">
                    <Text isTruncated>{row.curso}</Text>
                </Tooltip>
            )
        },
        {
            name: "Tipo Curso",
            selector: (row) => row.tipoCurso,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.tipoCurso} maxW="100vw">
                    <Text isTruncated>{row.tipoCurso}</Text>
                </Tooltip>
            )
        },
        {
            name: "Data coleta",
            selector: (row) => row.dataHora,
            compact: true,
            conditionalCellStyles: condicionalCol,
        },
        {
            name: "Status",
            width: "auto",
            selector: (row) => row.deletado,
            sortable: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => row.deletado !== null ?
                <Tag size="sm" variant="outline" w="100%" whiteSpace="nowrap" >Deletado {moment(row.deletado).fromNow()}</Tag> :
                <Tag size="sm" variant="solid">Ativo</Tag>
        },
        {
            name: "Ações",
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Box>
                    <Menu
                        strategy='fixed'
                        isLazy
                    >
                        <MenuButton>
                            <Icon as={BiMenu} w="24px" h="24px" />
                        </MenuButton>
                        <MenuList>
                            {/* <ViewQrcode item={row} /> */}
                            {
                                !row.deletado && (
                                    <MenuItem onClick={() => {
                                        setViewSelect(row)
                                        setOpenRemover(true)
                                    }}>
                                        Remover
                                    </MenuItem>
                                )
                            }
                            {
                                row.deletado && (
                                    <MenuItem isDisabled={loadRestaurar} onClick={() => Restaurar(row.id)}>
                                        Restaurar
                                    </MenuItem>
                                )
                            }

                        </MenuList>
                    </Menu>
                </Box>
            )
        }
    ]

    return (
        <Container maxW={"8xl"} pt="80px" minH="calc(100vh - 80px)" >
            <Card>
                <HStack spacing={4} justifyContent="flex-end" mb="3">
                    <Button onClick={() => history.replace("/")}
                        variant="gray-outline">
                        Voltar
                    </Button>
                </HStack>

                <DataTable
                    columns={columns}
                    data={list}
                    pagination
                    paginationComponentOptions={{
                        rowsPerPageText: "Qrcodes por página",
                        rangeSeparatorText: "de"
                    }}
                    highlightOnHover
                    customStyles={{
                        cells: {
                            style: {
                                fontSize: "12px",
                                fontWeight: "bold"
                            }
                        }
                    }}
                    progressPending={load}
                    progressComponent={
                        <Center p="42px">
                            <Spinner
                                w="120px"
                                h="120px"
                                color="gray.40"
                                thickness='4px'
                            />
                        </Center>
                    }
                    noDataComponent={
                        <Center p="8">
                            <Text>Nenhum Qrcode encontrado.</Text>
                        </Center>
                    }
                />
            </Card>
            <ModalRemoverFormulario formulario={viewSelect} open={openRemover} onClose={() => {
                setViewSelect(null);
                setOpenRemover(false);
            }}
                updateList={listLeads}
            />
        </Container>
    );
}

export default ClienteFormularios;