import { Button, HStack, ListItem, Text, UnorderedList, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Api from '../../Api/Api';
import CModal from '../CModal/Index';

function ModalRemoverFormulario({
    open,
    onClose,
    formulario,
    updateList
}) {
    const toast = useToast();
    const [load, setLoad] = useState(false);
    function remover() {
        setLoad(true)
        Api.get(`formulario/deletarCliente/${formulario.id}`)
            .then(res => {
                setLoad(false)
                toast({
                    title: "Lead excluido com sucesso",
                    duration: 5000,
                    isClosable: true,
                    status: "success"
                })
                onClose()
                updateList();
            })
            .catch(err => {
                setLoad(false)
                console.log({ [`formulario/deletarCliente/${formulario.id}`]: err.response.data })
            })
    }

    return (
        <CModal
            open={open}
            onClose={onClose}
            title="Excluir lead"
            footer={
                <HStack>
                    <Button variant="gray-outline" onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button isLoading={load} variant="dark" onClick={() => remover()}>
                        Remover lead
                    </Button>
                </HStack>
            }
        >
            <Text>Essa lead será declarada como excluida, isso significa que</Text>
            <UnorderedList>
                <ListItem>Essa lead não é mais listada no momento da exportação das leads</ListItem>
            </UnorderedList>

        </CModal>
    );
}

export default ModalRemoverFormulario;