import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import cidadeEstado from '../../../json/cidadeEstado';

function InputFormUF({ register, errors, disabled }) {
    const [list, setList] = useState([]);

    useEffect(() => {
        const ufs = cidadeEstado.estados.map(e => e.sigla);
        setList(ufs);
    }, [])

    return (
        <FormControl isRequired isInvalid={errors !== undefined} isDisabled={disabled}>
            <FormLabel htmlFor="uf" >UF</FormLabel>
            <Select {...register} id="uf" >
                <option value="">UF...</option>
                {list.map((e, index) => (
                    <option key={index} value={e}>{e}</option>
                ))}
            </Select>
            {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
        </FormControl>
    );
}

export default InputFormUF;