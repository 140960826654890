import React, { useEffect } from 'react';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import InputForm from '../Input/InputForm/Index';
import CModal from '../CModal/Index'
import { Button, HStack, Stack, useToast } from '@chakra-ui/react';
import Api from '../../Api/Api';
import InputIES from '../Input/InputForm/InputIES';
import InputCampus from '../Input/InputForm/InputCampus';
import InputformSelect from '../Input/InputForm/InputFormSelect';

const chrema = yup.object({
  nome: yup.string().matches(/^.*[ ].[a-zA-Z]*$/g, "Digite o seu nome completo."),
  telefone: yup.string().matches(/\([0-9]{2}\)\s[0-9]{5}-[0-9]{4}/g, { message: "Telefone inválido." }),
  unidade: yup.string(),
  nucleo: yup.string(),
  regional: yup.string(),
}).required("Campo obrigatório.")

function ModalEditarUsuario({ open, onClose, usuarioId, updateLista }) {
  const { handleSubmit, register, formState: { errors }, watch, resetField, setError, setValue, reset } = useForm({
    resolver: yupResolver(chrema)
  })
  const toast = useToast();


  useEffect(() => {
    if (usuarioId && open) {
      Api.get(`home/GetUsuario/${usuarioId}`)
        .then(r => {
          console.log({ [`home/GetUsuario/${usuarioId}`]: r.data })
          setValue("nome", r.data.nomeCompleto);
          setValue("telefone", r.data.telefone);
          setTimeout(() => setValue("unidade", r.data.unidade), 500)
          setValue("nucleo", r.data.nucleo);
          setValue("regional", r.data.regional)
        })
        .catch(err => {
          console.log({ [`home/GetUsuario/${usuarioId}`]: err.response })
        })
    }
  }, [usuarioId, open, setValue])

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset])

  function editar(data) {

    const formatData = {
      usuarioId,
      nomeCompleto: data.nome,
      telefone: data.telefone,
      unidade: data.unidade,
      nucleo: data.nucleo,
      regional: data.regional
    }

    Api.post("home/EditarUsuario", formatData)
      .then(res => {
        toast({
          title: "Usuário editado com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true
        })
        onClose()
        updateLista();
      })
      .catch(err => {
        console.log({ "Auth/SignUp": err.response.data });
        if (err.response.data === "USER_ALREADY_EXISTS")
          setError("email", { message: "Usuário com esse email já existe." })
        if (err.response.data === "USER_NOT_ALLOWED")
          toast({
            title: "Falha ao editar usuário",
            status: 'warning',
            duration: 5000,
            description: "Apenas administradores podem editar usuários no sistema."
          })
      })
  }


  return (
    <CModal
      title="Editar usuário"
      open={open}
      onClose={onClose}
      footer={
        <></>
      }
    >
      <form onSubmit={handleSubmit(editar)}>
        <Stack spacing={4}>
          <InputForm register={register("nome")} errors={errors.nome}
            name="nome" title='Nome completo' required />
          <InputIES register={register("nucleo")} errors={errors.nucleo}
            name="nucleo" title='Núcleo' required />
          <InputCampus register={register("unidade")}
            marca={watch("nucleo", false)} errors={errors.unidade}
            reset={resetField} name="unidade" title='Unidade' required />
          <InputForm register={register("telefone")} inputMask="(99) 99999-9999" errors={errors.telefone}
            name="telefone" title='Telefone' required />
          <InputformSelect register={register("regional")} errors={errors.regional}
            name="regional" placeholder='Regional...' title="Regional" typeList="Regional"
          />
          <HStack spacing={4} justifyContent="flex-end">
            <Button variant="gray-outline" onClick={onClose}>
              Cancelar
            </Button>
            <Button variant="gray" type='submit'>
              Editar Usuário
            </Button>
          </HStack>
        </Stack>
      </form>
    </CModal>
  );
}

export default ModalEditarUsuario;