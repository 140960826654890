import { Box, Center, Text } from '@chakra-ui/react';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useState, useEffect } from 'react';
import CriptografoService from '../service/Criptografo';

function VisualQRCode() {
    const [result, setResult] = useState("");
    const isMobile = window.innerWidth < 762;
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const info = urlParams.get('info');
        const token = urlParams.get('token');
        
        console.log({info, token})

        const newRoute = CriptografoService().formatarParaURL({
            id: info,
            token: token,
            isCadastro: true
        })

        setResult(newRoute);
    }, [])


    return (
        <Center w="100%" h="100vh">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <QRCodeCanvas size={isMobile ? "100" : "450"} value={result} />
                <Text mt="8px" maxW="450px" textAlign="center">Acesse a página de cadastro do cliente escaneando o QR Code acima.</Text>
            </Box>
        </Center>
    );
}

export default VisualQRCode;