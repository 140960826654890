import { Box, Button, Center, Container, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Api from '../Api/Api';
import { BiMenu } from 'react-icons/bi'
import Card from '../components/Card/Card'
import ModalDetalheUsuarios from '../components/ModalUsuarios/ModalDetalheUsuarios';
import ModalCriarUsuario from '../components/ModalUsuarios/ModalCriarUsuario';
import ModalEditarUsuario from '../components/ModalUsuarios/ModalEditarUsuario';
import { useHistory } from 'react-router-dom';
import ModalRemoverUsuario from '../components/ModalUsuarios/ModalRemoverUsuario';
function Usuarios() {
    const [users, setUsers] = useState([]);
    const [usuarioIdSelected, setUsuarioIdSelected] = useState(null);
    const [openDetalhe, setOpenDetalhe] = useState(null);
    const [openCriar, setOpenCriar] = useState(null);
    const [openEditar, setOpenEditar] = useState(null);
    const [loadList, setLoadList] = useState(false);
    const [openRemoverUsuario, setOpenRemoverUsuario] = useState(false);
    const history = useHistory();
    const toast = useToast();

    const listarUsuario = useCallback(() => {
        setLoadList(true)
        Api.get('/home/ListarUsuarios')
            .then(res => {
                setLoadList(false)
                setUsers(res.data)
            })
            .catch(err => {
                setLoadList(true)
                console.log({ '/home/ListarUsuarios': err.response })
                if (err.response.data === "USER_NOT_ALLOWED") {
                    toast({
                        title: "Falha no acesso",
                        description: "Você não é administrador para acessar essa página",
                        duration: 5000,
                        status: "warning"
                    })
                    history.replace("/");
                }
            })
    }, [history, toast])


    useEffect(() => {
        listarUsuario();
    }, [listarUsuario])

    const columns = [
        {
            name: "Nome",
            selector: (r) => r.nome,
            sortable: true,
            compact: true,
            cell: (row) => (
                <Tooltip label={row.nome} maxW="100vw">
                    <Text isTruncated>{row.nome}</Text>
                </Tooltip>
            )
        },
        {
            name: "Email",
            selector: (r) => r.email,
            compact: true,
            sortable: true,
            cell: (row) => (
                <Tooltip label={row.email} maxW="100vw">
                    <Text isTruncated>{row.email}</Text>
                </Tooltip>
            )
        },
        {
            name: "Telefone",
            selector: (r) => r.telefone,
            compact: true,
            sortable: true
        },
        {
            name: "Regional",
            selector: (r) => r.regional,
            compact: true,
            sortable: true
        },
        {
            name: "Unidade",
            selector: (r) => r.unidade,
            compact: true,
            sortable: true
        },
        {
            name: "Núcleo",
            selector: (r) => r.nucleo,
            compact: true,
            sortable: true
        },
        {
            name: "Ações",
            cell: (row) => (
                <Box>
                    <Menu
                        strategy='fixed'
                        isLazy
                    >
                        <MenuButton>
                            <Icon as={BiMenu} w="24px" h="24px" />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => { setOpenDetalhe(true); setUsuarioIdSelected(row.id) }}>
                                Detalhes
                            </MenuItem>
                            <MenuItem onClick={() => { setOpenEditar(true); setUsuarioIdSelected(row.id) }}>
                                Editar
                            </MenuItem>
                            <MenuItem onClick={() => { setOpenRemoverUsuario(true); setUsuarioIdSelected(row.id) }}>
                                Excluir usuário
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            )
        }
    ]

    return (
        <>
            <Container maxW="6xl" py="4">
                <HStack justifyContent="flex-end" mb="2">
                    <Button variant="gray-outline" onClick={() => history.replace('/')}>
                        Voltar
                    </Button>
                    <Button onClick={() => history.push('/usuarios/excluidos')}>
                        Listar usuarios deletados
                    </Button>
                    <Button
                        onClick={() => setOpenCriar(true)}
                        variant="gray"
                    >
                        Criar usuário
                    </Button>
                </HStack>
                <Card>
                    <DataTable
                        data={users}
                        pagination
                        paginationComponentOptions={{
                            rowsPerPageText: "Usuários por página",
                            rangeSeparatorText: "de"
                        }}
                        highlightOnHover
                        progressComponent={
                            <Center p="42px">
                                <Spinner
                                    w="120px"
                                    h="120px"
                                    color="gray.40"
                                    thickness='4px'
                                />
                            </Center>
                        }
                        progressPending={loadList}
                        noDataComponent={
                            <Center p="8">
                                <Text>Nenhum usuário encontrado.</Text>
                            </Center>
                        }
                        columns={columns}
                    />
                </Card>
            </Container>
            <ModalDetalheUsuarios usuarioId={usuarioIdSelected} open={openDetalhe} onClose={() => { setOpenDetalhe(false); setUsuarioIdSelected(false) }} />
            <ModalCriarUsuario
                onClose={() => { setOpenCriar(false); setUsuarioIdSelected(undefined) }}
                open={openCriar}
                updateLista={() => listarUsuario()}
            />
            <ModalEditarUsuario
                onClose={() => { setOpenEditar(false); setUsuarioIdSelected(undefined) }}
                open={openEditar} usuarioId={usuarioIdSelected}
                updateLista={() => listarUsuario()}
            />
            <ModalRemoverUsuario
                onClose={() => { setOpenRemoverUsuario(false); setUsuarioIdSelected(undefined) }}
                open={openRemoverUsuario} usuarioId={usuarioIdSelected}
                updateLista={() => listarUsuario()}
            />
        </>
    );
}

export default Usuarios;