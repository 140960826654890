import Api from "./Api";

class AuthApi {

    listForm({
        result = () => { },
        error = () => { }
    }) {
        Api.get('/Formulario')
            .then(res => { 
                result(res.data)
            })
            .catch(err => {
                console.log({ '/Formulario(catch)': err.response })
                if (err.response?.data === 'INVALID_EMAIL_PASSWORD')
                    alert("Email ou senha inválidos")
                error();
            })
    }

    listRanking({
        result = () => { },
        error = () => { }
    }) {
        Api.get('/Ranking')
            .then(res => {
                // console.log(res.data)
                result(res.data)
            })
            .catch(err => {
                console.log({ '/Auth/SignIn(catch)': err })
                if (err.response?.data === 'INVALID_EMAIL_PASSWORD')
                    alert("Email ou senha inválidos")
                error();
            })
    }


    atualizarUsuario({
        email,
        senha,
        unidade,
        nucleo
    }, {
        result = () => { },
        error = () => { }
    }) {
        const data = {
            email,
            senha,
            nucleo,
            unidade
        }
        Api.post('/Perfil', data)
            .then(res => {
                result();
            }).catch(err => {
                console.log({ '/AtualizarUsuario(catch)': err.response })
                if (err.response.data === 'INVALID_EMAIL_PASSWORD') {
                    alert("Email ou senha inválidos.")
                }
                error();
            })
    }


}

export default new AuthApi()