import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.timeout = 180000;
const BaseUrl = '/api/';

const Api = axios.create({
    baseURL: BaseUrl,
})


const token = localStorage.getItem("@HSCHOOL_TOKEN")
Api.defaults.headers.common["Authentication"] = 'Bearer ' + token;


export default Api;

export const baseUrl = BaseUrl;