import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import CriptografoService from '../../service/Criptografo';
import * as yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Container, Heading, HStack, Stack, useToast } from '@chakra-ui/react';
import InputForm from '../../components/Input/InputForm/Index';
import InputFormSelect from '../../components/Input/InputForm/InputFormSelect'
import InputFormEscolas from '../../components/Input/InputForm/InputFormEscola';
// .matches(/[0-9]{11}/g, { message: "Formato CPF inválido", excludeEmptyString: true }).min(11, "Formato de CPF inválido")
import ApiQRCodeBase from '../../Api/ApiQRcodeBase';
const chrema = yup.object({
    nome: yup.string().matches(/^.*[ ].[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]*$/g, "Digite o seu nome completo."),
    sexo: yup.string(),
    cpf: yup.string().notRequired(),
    email: yup.string().email("Email inválido."),
    telefone: yup.string().matches(/\([0-9]{2}\)\s[0-9]{5}-[0-9]{4}/g, { message: "Telefone inválido." }),
    serie: yup.string(),
    avaliacao: yup.string(),
    colegio: yup.string().required("Campo obrigatório.").test({
        name: "colegioRequired",
        message: "",
        test: (e) => e !== 'DEFAULT'
    }),
    outroColegio: yup.string().when("colegio", {
        is: (e) => e !== "OUTROS",
        then: yup.string().notRequired()
    }),
    tipoInstituicao: yup.string().notRequired(),
    curso: yup.string()
}).required("Campo obrigatório.")

function CadastroUser() {
    const { search } = useLocation()
    const [infosColaborador, setInfoColaborador] = useState();
    const { handleSubmit, register, setError, formState: { errors, }, setValue, watch } = useForm({
        resolver: yupResolver(chrema)
    })
    const toast = useToast();
    const history = useHistory();
    const [load, setLoad] = useState(false);
    const [confirmTokenQRCode, setConfirmTokenQRCode] = useState(false);

    useEffect(() => {
        // const urlParams = new URLSearchParams(window.location.search);
        const url = new URL(decodeURI(window.location.href));
        // console.log({url})
        const urlParams = new URLSearchParams(url.search);

        const myParamInfo = urlParams.get('info')
        const myParamToken = urlParams.get('token')

        ApiQRCodeBase.defaults.headers.common["Authentication"] = 'Bearer ' + myParamToken;
        ApiQRCodeBase.get(`/QRcode/GetResult/${myParamInfo}`)
            .then(res => {
                setInfoColaborador(res.data)
            })
            .catch(err => {
                console.log({ [`/QRcode/GetResult/${myParamInfo}`]: err.response.data });
                if(err.response.data === "INVALID_TOKEN") {
                    history.replace("/erro");
                }

            })

            ApiQRCodeBase.get(`qrcode/validarQRCode/${myParamInfo}`)
            .then(res => {
                if (res.data.encerrado !== null)
                    history.replace("/encerrado");

                if (res.data.deletado !== null)
                    history.replace("/removido")
            })
            .catch(err => {
                console.log({ [`qrcode/avaliarQRCode/${myParamInfo}`]: err.response })

                if (err.response.data === "QRCODE_NOT_FOUND") {
                    toast({
                        title: "Erro",
                        description: "Erro na validação do token",
                        status: "error",
                        duration: 5000,
                        isClosable: true
                    })
                    history.replace("/Removido")
                }
            })
        // console.log({ resultQueryValues })
        setConfirmTokenQRCode(true);
    }, [search, history])

    function cadastrarUsuario(data) {
        const request = {
            codigoAcao: infosColaborador?.id,
            responsavelAcao: infosColaborador?.emailResponsavel,
            dataAcao: infosColaborador?.dataAcao,
            Nome: data.nome,
            cpf: data.cpf,
            email: data.email,
            Telefone: data.telefone,
            Unidade: infosColaborador?.unidade,
            Curso: data.curso,
            cidade: infosColaborador?.cidade,
            uf: infosColaborador?.uf,
            sexo: data.sexo,
            marca: infosColaborador?.marca,
            escola: data.colegio === "OUTROS" ? data.outroColegio : data.colegio,
            instituicao: data.tipoInstituicao,
            serie: data.serie,
            fonte: infosColaborador?.fonte,
            tipoProcesso: infosColaborador?.tipoProcesso,
            mensagem: infosColaborador?.mensagem,
            ies: infosColaborador?.ies,
            avaliacao: data.avaliacao,
            assunto: "ESCOLAS",
            tipoCurso: "GRADUAÇÃO",
            metodoCurso: "PRESENCIAL",
            origemInteressado: "SALES FORCE DATA LOAD",
            canalOrigem: "SALES FORCE DATA LOAD",
            tipoOrigem: "SALES FORCE DATA LOAD",
            status: "NOVO"
        }
        if (infosColaborador.avaliacaoAcao && data.avaliacao === "") {
            setError("avaliacaoAcao", "Campo obrigatório.")
        } else {
            if (watch('cpf', false) === "" || watch('cpf', false) === undefined || watch('cpf', false).length === 11) {
                setLoad(true)
                // console.log({ "/Formulario/CadastrarUser(request)": request })
                ApiQRCodeBase.post("/Formulario/CadastrarUser", request)
                    .then(res => {
                        history.replace("Sucesso")
                        toast({
                            title: "Cadastrado(a) com sucesso!",
                            status: "success",
                            duration: 5000,
                            isClosable: true
                        })
                        setLoad(false)
                    })
                    .catch(err => {
                        console.log({ "/Formulario/CadastrarUser": err.response })
                        if (err.response.data === "QRCODE_EXPIRED")
                            history.replace("/encerrado")
                        setLoad(false)
                    })
            } else {
                setError("cpf", "Campo inválido.")
            }
        }
    }


    const setValueEscola = useCallback(() => {
        if (infosColaborador?.escola !== "OUTROS") {
            setValue("colegio", infosColaborador?.escola);
        } else {
            setValue("tipoInstituicao", infosColaborador?.tipoInstituicao);
        }
    }, [infosColaborador, setValue])

    return (
        <Container py="5">
            <Heading py="6">Cadastro</Heading>
            <form onSubmit={handleSubmit(cadastrarUsuario)}>
                {
                    confirmTokenQRCode && (
                        <Stack spacing={4}>
                            <InputForm register={register("nome")} errors={errors.nome}
                                name="nome" required title="Nome" />

                            <InputFormSelect isApiQRCodeToken typeList='Sexo' register={register("sexo")} errors={errors.sexo}
                                name="sexo" placeholder="Sexo..." required title="Sexo" />

                            <InputForm register={register("cpf")} errors={errors.cpf}
                                name="cpf" title="CPF" inputMask={"99999999999"} />

                            <InputForm register={register("email")} errors={errors.email}
                                name="email" required title="Email" type="email" />

                            <InputForm register={register("telefone")} errors={errors.telefone}
                                name="telefone" required title="Telefone" type="string" inputMask={"(99) 99999-9999"} />

                            <InputFormSelect isApiQRCodeToken register={register("serie")} errors={errors.serie}
                                name="serie" required title="Série" placeholder="Série..." typeList="Escolaridade" />

                            <InputFormEscolas isApiQRCodeToken unidade={
                                (infosColaborador?.ies === "ESTACIO" || infosColaborador?.ies === "IDOMED") ?
                                    undefined : infosColaborador?.unidade
                            }
                                disabled={infosColaborador?.escola !== "OUTROS"}
                                setValues={setValue}
                                register={register("colegio")} errors={errors.colegio}
                                loadList={setValueEscola} />

                            {
                                watch("colegio", false) === "OUTROS" && (
                                    <InputForm register={register("outroColegio")} errors={errors.outroColegio}
                                        name="outroColegio" required title="Nome da Escola" />
                                )
                            }

                            <InputFormSelect isApiQRCodeToken register={register("tipoInstituicao")} errors={errors.tipoInstituicao}
                                name="tipoInstituicao" placeholder="Tipo instituição..." title="Tipo de instituicao"
                                typeList="instituicao" required disabled={infosColaborador?.escola !== "OUTROS" && watch("colegio", false) !== "OUTROS"}
                                loadedItems={() => infosColaborador?.escola !== "OUTROS" && setValue("tipoInstituicao", infosColaborador?.tipoInstituicao)} />

                            <InputForm register={register("curso")} errors={errors.curso}
                                name="curso" required title="Curso de interesse"
                            />

                            <InputForm
                                register={register("avaliacao")}
                                required
                                name="avaliacao"
                                errors={errors.avaliacao}
                                disabled={!infosColaborador?.avaliacaoAcao}
                                title='Qual sua opinião sobre o evento?'
                            />

                            <HStack spacing={4}>
                                <Button type="submit" isLoading={load} variant="gray-outline">
                                    Enviar
                                </Button>
                            </HStack>
                        </Stack>
                    )
                }
            </form>
        </Container>
    );
}

export default CadastroUser;