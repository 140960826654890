import { Button, FormControl, FormErrorMessage, FormLabel, Input, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Text } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import { List } from 'react-virtualized';
import Api from '../../../Api/Api';
import ApiQRCodeBase from '../../../Api/ApiQRcodeBase';

function InputFormEscola({
    register,
    errors,
    unidade,
    resetEscola,
    disabled,
    watchUnidade,
    setValues,
    loadList = () => { },
    isApiQRCodeToken
}) {
    const [list, setList] = useState([]);
    const [openOption, setOpenOption] = useState(false)
    const outRef = useRef(null);
    const [pesquisa, setPesquisa] = useState("");
    const pesquisaRef = useRef();
    const pesquisarEscolaFilter = list.filter(e =>
        e.toUpperCase().includes(pesquisa.toUpperCase())
    )
    useEffect(() => {
        function handleClickOutside(event) {
            if (outRef.current && !outRef.current.contains(event.target)) {
                setOpenOption(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [outRef]);

    useEffect(() => {
        if (resetEscola)
            resetEscola()
    }, [watchUnidade])

    useEffect(() => {
        if (!isApiQRCodeToken) {
            if (unidade) {
                Api.get(`/escola/${unidade}`)
                    .then(res => {
                        console.log({ [`/escola/${unidade}`]: res.data })
                        setList(res.data.map(e => e.nome));
                    })
                    .catch(err => {
                        console.log({ [`/escola/${unidade}`]: err.response.data })
                    })
            } else {
                Api.get(`/escola/ListAll`)
                    .then(res => {
                        setList(res.data.map(e => e.nome));
                    })
                    .catch(err => {
                        console.log({ [`/escola/ListAll(catch)`]: err.response })
                    })
            }
        } else {
            if (unidade) {
                ApiQRCodeBase.get(`/escola/${unidade}`)
                    .then(res => {
                        setList(res.data.map(e => e.nome));
                    })
                    .catch(err => {
                        console.log({ [`/escola/${unidade}`]: err.response.data })
                    })
            } else {
                ApiQRCodeBase.get(`/escola/ListAll`)
                    .then(res => {
                        setList(res.data.map(e => e.nome));
                    })
                    .catch(err => {
                        console.log({ [`/escola/ListAll(catch)`]: err.response.data })
                    })
            }
        }
    }, [unidade])

    useEffect(() => {
        if (list.length !== 0) {
            loadList()
        }
    }, [list, loadList])

    return (
        <div ref={outRef}>

            <Popover

                isOpen={openOption} autoFocus={false}>
                <PopoverTrigger>
                    <FormControl
                        isRequired
                        isInvalid={errors !== undefined}
                        isDisabled={disabled} >
                        <FormLabel htmlFor="colegio" >Escola</FormLabel>
                        <Input
                            {...register}
                            id="escola"
                            name='colegio'
                            autoComplete='off'
                            readOnly
                            onClick={() => {
                                setOpenOption(true);
                                pesquisaRef.current.focus()
                            }}
                        />
                        {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
                    </FormControl>
                </PopoverTrigger>
                <PopoverContent w="720px">
                    <PopoverHeader>
                        <Input
                            value={pesquisa}
                            onChange={(e) => setPesquisa(e.target.value)}
                            placeholder="Pesquise a escola..."
                            ref={pesquisaRef}
                        />
                    </PopoverHeader>
                    <PopoverBody maxH="380px"
                        // overflowY="auto"
                        // overflowX="hidden"
                    >
                        {/* {
                            pesquisarEscolaFilter.map((e, index) => (
                                <Button
                                    display="block"
                                    key={index}
                                    w="100%"
                                    textAlign="start"
                                    justifyContent="flex-start"
                                    flexWrap="nowrap"
                                    size="sm"
                                    onClick={() => {
                                        setValues("colegio", e);
                                        setOpenOption(false)
                                    }}
                                >
                                    {e}
                                </Button>
                            ))
                        } */}
                        <Text fontWeight="bold" fontSize=".66rem" mb="2">Total de {list.length} escolas</Text>
                        <List
                            width={640}
                            autoWidth
                            autoContainerWidth
                            height={300}
                            rowRenderer={({ index, key, style }) => (
                                <Button
                                    display="block"
                                    style={style}
                                    key={key}
                                    w="100%"
                                    textAlign="start"
                                    justifyContent="flex-start"
                                    flexWrap="nowrap"
                                    size="sm"
                                    onClick={() => {
                                        setValues("colegio", pesquisarEscolaFilter[index]);
                                        setOpenOption(false)
                                    }}
                                >
                                    {pesquisarEscolaFilter[index]}
                                </Button>
                            )}
                            rowCount={pesquisarEscolaFilter.length}
                            rowHeight={32}
                        />
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </div>

    );
}

export default InputFormEscola;