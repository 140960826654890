import React, { useEffect, useState } from 'react';
import CModal from '../CModal/Index';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import Api from '../../Api/Api';
import { Button, HStack, FormControl, FormErrorMessage, FormLabel, Input, Select, Stack, useToast } from '@chakra-ui/react';

const chrema = yup.object({
    nome: yup.string().required("Insere o nome da escola."),
    ies: yup.string().notRequired()
}).required("Campo obrigatório.");

function ModalEditarEscola({
    escola,
    open,
    updateList,
    onClose
}) {
    const [ies, setIes] = useState([]);
    const [load, setLoad] = useState(false);
    const toast = useToast();

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(chrema)
    })

    useEffect(() => {
        Api.get("unidade/ListAll")
            .then(res => {
                setIes(res.data)
            })
    }, [])

    useEffect(() => {
        if (escola) {
            setValue("nome", escola.nome)
            setValue("ies", escola.ies)
        }
    }, [escola, setValue])

    function editarEscola(data) {
        setLoad(true);
        const request = {
            nome: data.nome,
            unidade: data.ies,
            escolaId: escola.id
        }
        Api.post("Escola/Editar", request)
            .then(() => {
                toast({
                    title: "Escola registrada com sucesso!",
                    duration: 5000,
                    isClosable: true,
                    status: "success"
                })
                setLoad(false)
                onClose()
                updateList()
            })
            .catch(err => {
                setLoad(false)
                console.log({ "Escola/Criar": err.response.data });
            })
    }

    return (
        <CModal
            open={open}
            onClose={onClose}
            title='Editar Escola'
        >
            <form onSubmit={handleSubmit(editarEscola)}>
                <Stack spacing={4}>
                    <FormControl isInvalid={errors.nome !== undefined}>
                        <FormLabel>Nome da escola</FormLabel>
                        <Input {...register("nome")} name="nome" />
                        <FormErrorMessage>{errors.nome?.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.ies !== undefined}>
                        <FormLabel>IES da escola</FormLabel>
                        <Select {...register("ies")}>
                            {
                                ies?.map((e, index) => (
                                    <option key={index} value={e.nome}>{e.nome}</option>
                                ))
                            }
                            <option value="">(nenhum)</option>
                        </Select>
                        <FormErrorMessage>{errors.ies?.message}</FormErrorMessage>
                    </FormControl>

                    <HStack spacing={4} mb="4">
                        <Button variant='gray-outline' onClick={onClose}>
                            Cancelar
                        </Button>
                        <Button isLoading={load} type="submit" variant="dark">
                            Editar escola
                        </Button>
                    </HStack>
                </Stack>
            </form>

        </CModal>
    );
}

export default ModalEditarEscola;