import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import CModal from '../CModal/Index';
import { Center, InputGroup, Input, InputRightElement, Icon, useToast, Box, Text, Spinner } from '@chakra-ui/react';
import moment from 'moment'
import CriptografoService from '../../service/Criptografo';
import { AiOutlineCopy } from 'react-icons/ai'
import Api from '../../Api/Api';
function ViewQrcode({ item, open, onClose = () => { } }) {
    const [result, setResult] = useState("");
    const [resultView, setResultView] = useState("");
    const toast = useToast();
    useEffect(() => {
        if (open) {
            setResult("")

            const res = { emailResponsavel: item.emailResponsavel }

            Api.post("QRcode/GenerateTokenQRCode", res)
                .then(res => {

                    // const itemForm = {
                    //     id: item.id,
                    //     ies: item.ies,
                    //     unidade: item.unidade,
                    //     mensagem: item.mensagem,
                    //     fonte: item.fonte,
                    //     tipoProcesso: item.tipoProcesso,
                    //     uf: item.uf,
                    //     cidade: item.cidade,
                    //     colegio: item.escola,
                    //     tipoInstituicao: item.tipoInstituicao,
                    //     emailResponsavel: item.emailResponsavel,
                    //     dataAcao: moment(item.createdAt).format("YYYY-MM-DDTHH:mm:ss"),
                    //     tokenQRCode: res.data,
                    //     avaliacaoAcao: item.avaliacaoAcao
                    // }
                    // const cryptdata = CriptografoService("_HFencryptS#2022").criptografar(itemForm);
                    const viewNewData = CriptografoService().formatarParaURL({
                        id: item.id,
                        token: res.data,
                        isCadastro: false
                    })
                    
                    const newRoute = CriptografoService().formatarParaURL({
                        id: item.id,
                        token: encodeURIComponent(res.data),
                        isCadastro: true
                    });
                    // console.log({ viewNewData, newRoute })
                    setResult(newRoute);
                    setResultView(viewNewData)

                })
                .catch(err => {
                    console.log({ "QRcode/GenerateTokenQRCode(catch)": err.response.data })
                })
        }
    }, [open, item])

    return (
        <>
            <CModal
                title="QR Code"
                onClose={() => onClose()}
                open={open}
            >
                <Center p="4">
                    <Box>
                        <Text mb="8px">O que os usuários irão escanear para o cadastro:</Text>
                        <Box
                            borderWidth="1px"
                            borderColor="gray.80"
                            borderRadius="16px"
                            p="4"
                        >
                            {
                                resultView !== "" ? (
                                    <QRCodeCanvas value={result} size="380" />
                                ) : (
                                    <Center w="380px" h="380px" color="#bbb">
                                        <Spinner />
                                    </Center>
                                )
                            }
                        </Box>
                        <Text mb="8px" mt="4">Link do Formulário:</Text>
                        <InputGroup
                            cursor="pointer"
                            onClick={() => {
                                navigator.clipboard.writeText(result)
                                toast({
                                    title: "Copiado!",
                                    status: "info",
                                    duration: 2000,
                                })
                            }} >
                            <Input
                                value={result}
                                isDisabled
                                _disabled={{ bg: "white", color: "black" }}
                            />
                            <InputRightElement
                                children={<Icon as={AiOutlineCopy} />}
                            />
                        </InputGroup>

                        <Text mb="8px" mt="4">Visualização do QR Code:</Text>
                        <InputGroup
                            cursor="pointer"
                            onClick={() => {
                                navigator.clipboard.writeText(resultView)
                                toast({
                                    title: "Copiado!",
                                    status: "info",
                                    duration: 2000,
                                })
                            }} >
                            <Input
                                value={resultView}
                                isDisabled
                                _disabled={{ bg: "white", color: "black" }}
                            />
                            <InputRightElement
                                children={<Icon as={AiOutlineCopy} />}
                            />
                        </InputGroup>

                    </Box>
                </Center>
            </CModal>
        </>
    );
}

export default ViewQrcode;