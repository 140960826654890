// import 'bootstrap/dist/css/bootstrap.css';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import defaultStyle from './style/DefaultTheme';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ChakraProvider resetCSS theme={defaultStyle}>
      <App />
    </ChakraProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

