import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
function SystemAuth({ children }) {
    const [isChecked, setIsChecked] = useState(true);
    const timer = useRef(null);

    useEffect(() => {
        timer.current = setInterval(() => {
            const token = localStorage.getItem("@HSCHOOL_TOKEN");
            const exp = localStorage.getItem("@HSCHOOL_EXP");
            const role = localStorage.getItem("@HSCHOOL_ROLE");
            if (!token || !exp || !role) {
                setIsChecked(false)
            }
            if(moment().diff(exp, "seconds") > 1) { 
                setIsChecked(false);
                localStorage.removeItem("@HSCHOOL_TOKEN");
                localStorage.removeItem("@HSCHOOL_EXP");
                localStorage.removeItem("@HSCHOOL_ROLE");
            }
        }, 1000)

        return () => {
            clearInterval(timer.current)
        }
    }, [])


    return isChecked ? children : <Redirect to="/login" />
}

export default SystemAuth;