import { Box, Button, HStack } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import Api from '../../Api/Api';
import Modal from '../CModal/Index';
import ModalErro from './ModalError';
function ImportarFormulario() {
    const [open, setOpen] = useState(false);
    const formRef = useRef(null);
    const [file, setFile] = useState(null);
    const [load, setLoad] = useState(false);
    const [listError, setListError] = useState([]);
    const [isError, setIsError] = useState(false);
    function importarFormulario() {
        if (file !== null) {
            const form = new FormData();
            form.append("BancoFormularios", file);
            setLoad(true);
            Api.post('/Formulario/Importacao', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(() => {
                    alert("Formulários importados com sucesso!")
                    setOpen(false);
                    setLoad(false);
                })
                .catch(err => {
                    console.log({ '/Importacao': err.response });
                    setLoad(false);

                    if (err.response?.data === "CAMPO_VAZIO") {
                        alert("Alguns campos estão vazios, preenche-os e tente novamente.");
                    }

                    if (err.response.data?.length !== 0) {
                        setListError(err.response.data);
                        setIsError(true);
                    }
                    // if (err.response.data.status === "COLABORADOR_NOT_FOUND")
                    //     alert(`Formulários não foram salvos porque não foi possível encontrar o COLABORADOR na planilha (linha ${err.response.data.linha}), preenche adequadamente e tente novamente.`)

                    // if (err.response.data.status === "IES_NOT_FOUND")
                    //     alert(`Formulários não foram salvos porque não foi possível encontrar o IES na planilha (linha ${err.response.data.linha}), preenche adequadamente e tente novamente.`)

                    // if (err.response.data.status === "UNIDADE_NOT_FOUND")
                    //     alert(`Formulários não foram salvos porque não foi possível encontrar o UNIDADE na planilha (linha ${err.response.data.linha}), preenche adequadamente e tente novamente.`)
                })
        } else {
            alert("Selecione um documento .xlsx")
        }
    }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="dark"
                size="sm"
            >
                Importar Formulário
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                footer={
                    <HStack spacing={4}>
                        <Button
                            onClick={() => setOpen(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="gray"
                            onClick={() => importarFormulario()}
                            isLoading={load}
                        >
                            Importar Formulário
                        </Button>
                    </HStack>
                }
                title="Importar formulário"
            >
                <Box
                    h="100%" w="100%" borderRadius="12px" p="4" mb="2" py="8" textAlign="center" cursor="pointer"
                    border="1px"
                    borderColor="gray.40"
                    onClick={() => {
                        formRef.current.click()
                    }}
                >
                    {!file && "Selecione um documento .xlsx"}
                    {file && <h5>{file?.name}</h5>}
                </Box>
                <input
                    style={{ display: "none" }}
                    ref={formRef}
                    accept=".xlsx"
                    type="file"
                    className='d-none'
                    onAbort={() => setFile(null)}
                    onChange={(e) => {
                        if (e.currentTarget.files[0]) {
                            setFile(e.currentTarget.files[0]);
                        } else {
                            setFile(null)
                            console.log(e.currentTarget.files[0])
                        }
                    }}
                />
            </Modal>
            <ModalErro
                errorList={listError}
                onClose={() => setIsError(false)}
                open={isError}
            />
        </>
    );
}

export default ImportarFormulario;