import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import React from 'react';
import InputMask from 'react-input-mask'

// import { Container } from './styles';

function InputForm({ register, errors, name, required, title, disabled, type = "text", inputMask }) {

  return (
    <FormControl isRequired={required} isInvalid={errors !== undefined}
      isDisabled={disabled}>
      <FormLabel htmlFor={name} >{title}</FormLabel>
      <Input
        {...register}
        as={inputMask && InputMask}
        mask={inputMask}
        name={name} id={name} type={type} />
      {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
    </FormControl>
  )
}

export default InputForm;