import { Box, Button, Center, Container, HStack, Image, Stack, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import logo from '../assets/logonew.png'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Api from '../Api/Api';
import { useHistory } from 'react-router-dom';
import InputForm from '../components/Input/InputForm/Index';
const te = yup.object({
    email: yup.string().email("Email inválido.")
})

function EsqueciSenha() {
    const { handleSubmit, register, formState: { errors } } = useForm({
        resolver: yupResolver(te)
    })
    const [load, setLoad] = useState();
    const t = useToast();
    const history = useHistory();
    function esqueci(data) {
        setLoad(true)
        Api.post("Auth/ForgotPassword", data)
            .then(() => {
                history.push("/RedefinirSenha", { email: data.email })
                t({
                    title: "O código foi gerado e enviado em sua caixa de email",
                    duration: 5000,
                    status: "info",
                    isClosable: false
                })
                setLoad(false)
            })
            .catch(err => {
                console.log({ "Auth/ForgotPassword(catch)": err.response.data })
                setLoad(true)
            })
    }

    return (
        <Box bg="#4f6170" w="100%" h="100vh" >
            <Container maxW="2xl" h="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    bg="white"
                    borderRadius="42px"
                    boxShadow="0px 0px 12px 8px #00000030"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p="8"
                    py="16"
                >
                    <Box w="100%">
                        <Center mb="4" >
                            <Image src={logo} w="75%" />
                        </Center>
                        <form onSubmit={handleSubmit(esqueci)}>
                            <Stack spacing={4}>
                                <InputForm register={register("email")} errors={errors.email}
                                    name="email" required title="Email" type="email"
                                />
                            </Stack>
                            <HStack spacing={4} mt="10">
                                <Button variant="gray-outline" onClick={() => history.goBack()}>
                                    Voltar
                                </Button>
                                <Button type="submit" isLoading={load}
                                    variant="gray" size="lg"
                                >
                                    Receber código
                                </Button>
                            </HStack>
                        </form>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default EsqueciSenha;