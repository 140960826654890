import CryptoJS from 'crypto-js'
function CriptografoService(key) {
    const encrypt = (content, password) => CryptoJS.RC4Drop.encrypt(JSON.stringify(content), password, {
        drop: 3072 / 4
    })
    const decrypt = (crypted, password) => JSON.parse(CryptoJS.RC4Drop.decrypt(crypted, password, {
        drop: 3072 / 4
    }).toString(CryptoJS.enc.Utf8))

    function criptografar(item) {
        const crypt = encrypt(item, key);
        return crypt;
    }

    function decriptografar(context) {
        const descry = decrypt(context, key);
        return descry;
    }

    function formatarParaURL({ id, token, isCadastro }) {

        if (isCadastro) {
            const routeOrigin = window.location.hostname === "localhost" ?
                `${window.location.origin}/CadastroUser?info=${id}&token=${token}` :
                `https://highschool.henrystore.com.br/CadastroUser?info=${id}&token=${token}`;
            // var urlOrigin = new URL(routeOrigin);
            // urlOrigin.searchParams.set('info', id);
            // urlOrigin.searchParams.set('token', token);
            // const encoded = encodeURI(urlOrigin);
            var urlOriginEncode = encodeURI(routeOrigin)

            console.log({urlOriginEncode})
            return urlOriginEncode;
        } else {
            const routeOrigin = window.location.hostname === "localhost" ?
                `${window.location.origin}/Visual?info=${id}&token=${token}` :
                `https://highschool.henrystore.com.br/Visual?info=${id}&token=${token}`;
            var urlOrigin = new URL(routeOrigin);
            // urlOrigin.searchParams.set('info', id);
            // urlOrigin.searchParams.set('token', token);
            // const encoded = encodeURI(urlOrigin)
            // console.log({encoded})
            var urlOriginEncode = encodeURI(routeOrigin)
            return urlOriginEncode;
        }
    }

    return {
        criptografar,
        decriptografar,
        formatarParaURL
    }
}

export default CriptografoService;