import React from 'react';
import { LoadingContainer } from './style';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
function Botao({
    onClick,
    text,
    tema,
    load
}) {

    return (
        <button disabled={load} className={tema} onClick={onClick}>
            <p style={{ opacity: load ? "0" : "1", margin: 0, padding: 0 }}>{text}</p>
            {
                load && (
                    <LoadingContainer>
                        <AiOutlineLoading3Quarters size={20} color="#fff" className='loading_rotate' />
                    </LoadingContainer>
                )
            }
        </button>
    );
}

export default Botao;