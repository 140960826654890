import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import SystemAuth from './components/SystemAuth';
import AtualizarUsuario from './pages/AtualizarUsuario';
import CadastroUser from './pages/CadastroUser/CadastroUser';
// import Carregando from './pages/Carregando';
import CriarQrcode from './pages/CriarQrcode';
import Download from './pages/Download';
// import Home from './pages/Home';
import Login from './pages/login';
import Privacidade from './pages/Privacidade';
import Qrcodes from './pages/Qrcodes';
import VisualQRCode from './pages/VisualQRCode';
// import { Global } from './style/Global';
import UserSucesso from './pages/CadastroUser/UserSucesso';
import DetalheQRcode from './pages/DetalheQRcode';
import Encerrado from './pages/CadastroUser/Encerrado';
import Usuarios from './pages/Usuarios';
import UsuariosExcluidos from './pages/UsuariosExcluidos';
// import HomeCliente from './pages/HomeCliente';
import ListaSuspensa from './pages/ListaSuspensas';
import Removido from './pages/CadastroUser/Removido';
import ClienteFormularios from './pages/ClienteFormularios';
import Carregando from './pages/Carregando';
import EsqueciSenha from './pages/EsqueciSenha';
import RedefinirSenha from './pages/RedefinirSenha';
import Erro from './pages/CadastroUser/Erro';

export default class App extends Component {
  static displayName = App.name;
  render() {
    return (
      // <Global />
      <>
        <ThemeProvider theme={{}}>
          <Switch>
            <Route path="/Login" component={Login} exact />
            <Route path="/EsqueciSenha" component={EsqueciSenha} exact />
            <Route path="/RedefinirSenha" component={RedefinirSenha} exact />
            <Route path="/CadastroUser" component={CadastroUser} exact />
            <Route path="/Visual" component={VisualQRCode} exact />
            <Route path="/Sucesso" component={UserSucesso} exact />
            <Route path="/encerrado" component={Encerrado} exact />
            <Route path="/removido" component={Removido} exact />
            <Route path="/erro" component={Erro} exact />

            <SystemAuth>
              {/* <Route path="/" component={Carregando} exact /> */}
              {/* ADM */}
              {/* <Route path="/principal" component={HomeCliente} exact />
              <Route path="/principal" component={HomeCliente} exact /> */}
              {/* CLIENTE */}
              <Route path="/" component={Carregando} exact />
              {/*  */}
              <Route path="/Download" component={Download} exact />
              <Route path="/listas" component={ListaSuspensa} exact />
              <Route path="/leads" component={ClienteFormularios} exact />
              <Route path="/AtualizarUsuario" component={AtualizarUsuario} exact />
              <Route path="/qrcode" component={Qrcodes} exact />
              <Route path="/qrcode/criar" component={CriarQrcode} exact />
              <Route path="/qrcode/detalhes" component={DetalheQRcode} exact />
              <Route path="/usuarios" component={Usuarios} exact />
              <Route path="/usuarios/excluidos" component={UsuariosExcluidos} exact />
            </SystemAuth>

            <Route path="/Privacidade" component={Privacidade} exact />
          </Switch>
        </ThemeProvider>
      </>
    );
  }
}