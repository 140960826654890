import { Box } from '@chakra-ui/react';
import React from 'react';

function Card({ children }) {
    return (
        <Box
            bg="white"
            p="4"
            borderRadius="16px"
            w="100%"
        >
            {children}
        </Box>
    );
}

export default Card;