import { Button, Center, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import {BiError} from 'react-icons/bi'
function Erro() {
  return (
    <Center h="100vh" flexDirection="column" p="4">
        <Icon as={BiError} w="240px" h="240px" mb="4" />
        <Text textAlign="center" mb="2">Não foi possível carregar o cadastro.</Text>
        <Text textAlign="center" fontSize=".74rem" mb="2" color="gray.400">Token inválido.</Text>
        <Button variant="gray-outline" onClick={() => window.close()}>
            Fechar
        </Button>
    </Center>
  );
}

export default Erro;