import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Api from '../../../Api/Api';

function InputIES({ register, errors, disabled, title = "IES de interesse" }) {
    const [ies, setIes] = useState([]);


    useEffect(() => {
        Api.get('/Marca')
            .then(res => {
                console.log({'/Marca': res.data})
                setIes(res.data)
            })
            .catch(err => {
                console.log({ "/Marca(catch)": err.response.data })
            })
    }, [])

    return (
        <FormControl isRequired isInvalid={errors !== undefined} isDisabled={disabled}>
            <FormLabel  htmlFor='ies' >{title}</FormLabel>
            <Select {...register} id="ies" >
                <option value="">Selecione um IES...</option>
                {ies.map((e, index) => (
                    <option key={index} value={e}>{e}</option>
                ))}
            </Select>
            {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
        </FormControl>
    );
}

export default InputIES;