import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Api from '../../../Api/Api';
import ApiQRCodeBase from '../../../Api/ApiQRcodeBase';

function InputformSelect({
    register,
    errors,
    name,
    title,
    typeList,
    required,
    placeholder,
    disabled,
    loadedItems = () => { },
    isApiQRCodeToken = false
}) {
    const [list, setList] = useState([]);


    useEffect(() => {
        if(!isApiQRCodeToken) {
            Api.get(`/${typeList}`)
                .then(res => {
                    setList(res.data)
                    // setTimeout(() => { loadedItems() }, 500)
                })
                .catch(err => {
                    console.log({ "/Marca(catch)": err.response.data })
                })
        } else {
            ApiQRCodeBase.get(`/${typeList}`)
                .then(res => {
                    setList(res.data)
                    // setTimeout(() => { loadedItems() }, 500)
                })
                .catch(err => {
                    console.log({ "/Marca(catch)": err.response.data })
                })
        }
    }, [typeList, isApiQRCodeToken])

    useEffect(() => {
        if (list.length !== 0) {
            loadedItems()
        }
    }, [list, loadedItems])

    return (
        <FormControl isRequired={required} isInvalid={errors !== undefined} isDisabled={disabled}>
            <FormLabel htmlFor={name} >{title}</FormLabel>
            <Select {...register} id={name} >
                <option value="">{placeholder}</option>
                {list.map((e, index) => (
                    <option key={index} value={e}>{e}</option>
                ))}
            </Select>
            {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
        </FormControl>
    );
}

export default InputformSelect;