import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import Api from '../../../Api/Api';

function InputCampus({ register, errors, marca, reset, disabled }) {
    const [unidade, setUnidade] = useState([]);

    useEffect(() => {
        console.log({ marca });
        reset("colegio")
        if (marca) {
            reset("unidade")
            Api.get(`/Unidade/${marca}`)
                .then(res => {
                    console.log({ [`/Unidade/${marca}`]: res.data })
                    setUnidade(res.data.map(e => e.nome))
                })
                .catch(err => {
                    console.log({ [`/Unidade/${marca}(catch)`]: err.response.data })
                })
        }
    }, [marca, reset])

    return (
        <FormControl isRequired isInvalid={errors !== undefined} isDisabled={disabled}>
            <FormLabel htmlFor='unidade' >Unidade</FormLabel>
            <Select {...register} id="unidade" >
                <option value="">Unidade...</option>
                {unidade.map((e, index) => (
                    <option key={index} value={e}>{e}</option>
                ))}
            </Select>
            {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
        </FormControl>
    );
}

export default InputCampus;