import React, { useState, useRef, useEffect } from 'react';
import CriptografoService from '../../service/Criptografo';
import moment from 'moment'
import { QRCodeCanvas } from 'qrcode.react';
import { Box, Center, MenuItem, Text } from '@chakra-ui/react';
import ReactToPrint from 'react-to-print'
import Api from '../../Api/Api';

function PrintQrcode({ item }) {
    const [result, setResult] = useState("");
    const printRef = useRef(null)

    useEffect(() => {
        const res = { emailResponsavel: item.emailResponsavel }
        Api.post("QRcode/GenerateTokenQRCode", res)
            .then(res => {
                // const itemForm = {
                //     id: item.id,
                //     ies: item.ies,
                //     unidade: item.unidade,
                //     mensagem: item.mensagem,
                //     fonte: item.fonte,
                //     tipoProcesso: item.tipoProcesso,
                //     uf: item.uf,
                //     cidade: item.cidade,
                //     colegio: item.escola,
                //     tipoInstituicao: item.tipoInstituicao,
                //     emailResponsavel: item.emailResponsavel,
                //     dataAcao: moment(item.createdAt).format("YYYY-MM-DDTHH:mm:ss"),
                //     tokenQRCode: res.data
                // }
                // console.log({"QRcode/GenerateTokenQRCode(response)": itemForm})
                // const cryptdata = CriptografoService("_HFencryptS#2022").criptografar();
                const viewNewData = CriptografoService().formatarParaURL({
                    id: item.id,
                    token: res.data,
                    isCadastro: true
                })
                setResult(viewNewData)
            })
            .catch(err => {
                console.log({ "QRcode/GenerateTokenQRCode(catch)": err.response.data })
            })

    }, [item])

    return (
        <>
            <ReactToPrint
                trigger={() => <MenuItem>Imprimir</MenuItem>}
                content={() => printRef.current}
            />
            <Box display="none">
                <div ref={printRef}>
                    <Center w="100%" h="100vh" flexDirection="column">
                        <QRCodeCanvas value={result} size="600" />
                        <Text mt="8">Tempo de vencimento do QRCode imprimido: {moment().add(1, 'year').format("DD/MM/YYYY")} </Text>
                    </Center>
                </div>
            </Box>
        </>
    )
}

export default PrintQrcode;