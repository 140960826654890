import React from 'react';
import { InputStyle, Title } from './Style';

function Input({
    value,
    title,
    type,
    onChange
}) {
    return (
        <div className="w-100 d-flex flex-direction-column">
            <Title>{title}</Title>
           
           <InputStyle
                value={value}
                onChange={onChange}
                type={type}
                style={{
                    width: "100%"
                }}
            />
        </div>
    );
}

export default Input;