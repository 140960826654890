import { Box, Button, Center, Container, HStack, Image, Stack, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import logo from '../assets/logonew.png'
import InputMask from 'react-input-mask'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Api from '../Api/Api';
import { useHistory, useLocation } from 'react-router-dom';
import InputForm from '../components/Input/InputForm/Index';
const te = yup.object({
    email: yup.string().email("Email inválido."),
    code: yup.number(),
    password: yup.string(),
    repeatPassword: yup.string().oneOf([yup.ref("password")], "Senha e repetir senha não coincidem.")
}).required()

function RedefinirSenha() {
    const { handleSubmit, setValue, register, formState: { errors } } = useForm({
        resolver: yupResolver(te)
    })
    const { state } = useLocation()
    const [load, setLoad] = useState();
    const t = useToast();
    const history = useHistory();

    useEffect(() => {
        if (state) {
            console.log({
                "state" : state,
                "email": state?.email
            })
            setValue("email", state?.email);
        }
    }, [state])

    function redefinir(data) {
        setLoad(true)
        Api.post("Auth/RedefinePassword", data)
            .then(() => {
                t({
                    title: "Senha redefinida com sucesso!",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                history.replace("/login")
                setLoad(false)
            })
            .catch(err => {
                console.log({ "Auth/RedefinePassword(catch)": err.response.data })
                setLoad(true)
            })
    }

    return (
        <Box bg="#4f6170" w="100%" h="100vh" >
            <Container maxW="2xl" h="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    bg="white"
                    borderRadius="42px"
                    boxShadow="0px 0px 12px 8px #00000030"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p="8"
                    py="16"
                >
                    <Box w="100%">
                        <Center mb="4" >
                            <Image src={logo} w="75%" />
                        </Center>
                        <form onSubmit={handleSubmit(redefinir)}>
                            <Stack spacing={4}>
                                <InputForm disabled register={register("email")} errors={errors.email}
                                    name="email" required title="Email" type="email"
                                />
                                <InputForm register={register("code")} inputMask="999999" errors={errors.code}
                                    name="code" required title="Código" type="code"
                                />
                                <InputForm register={register("password")} errors={errors.password}
                                    name="password" required title="Senha" type="password"
                                />
                                <InputForm register={register("repeatPassword")} errors={errors.repeatPassword}
                                    name="repeatPassword" required title="Confirmar senha" type="repeatPassword"
                                />
                            </Stack>
                            <HStack spacing={4} mt="10">
                                <Button variant="dark" size="lg" onClick={() => history.push("/esqueciSenha")} >
                                    Cancelar
                                </Button>
                                <Button type="submit" isLoading={load}
                                    variant="dark" size="lg"
                                >
                                    Redefinir senha
                                </Button>
                            </HStack>
                        </form>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default RedefinirSenha;