import { Box, Button, Center, Container, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { BiMenu } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import Api from '../Api/Api';
import Card from '../components/Card/Card';
import ModalDetalheUsuarios from '../components/ModalUsuarios/ModalDetalheUsuarios';

function UsuariosExcluidos() {
  const [users, setUsers] = useState([]);
  const [usuarioIdSelected, setUsuarioIdSelected] = useState(null);
  const [openDetalhe, setOpenDetalhe] = useState(null);
  const [loadList, setLoadList] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const loadLista = useCallback(() => {
    setLoadList(true)
    Api.get('/home/ListarDeletados')
      .then(res => {
        setLoadList(false)
        setUsers(res.data)
      })
      .catch(err => {
        setLoadList(true)
        console.log({ '/home/ListarDeletados': err.response })
        if (err.response.data === "USER_NOT_ALLOWED") {
          toast({
            title: "Falha no acesso",
            description: "Você não é administrador para acessar essa página",
            duration: 5000,
            status: "warning"
          })
          history.replace("/");
        }
      })
  }, [history, toast])

  useEffect(() => {
    loadLista();
  }, [loadLista])

  function restaurarUsuario(userId) {
    Api.get(`/home/RestaurarUsuario/${userId}`)
      .then(res => {
        toast({
          title: "Usuario restaurado",
          isClosable: true,
          duration: 5000
        })
        loadLista();
      })
      .catch(err => {
        console.log({ [`/home/RestaurarUsuario/${userId}`]: err.response.data });
        if (err.response.data === "USER_NOT_ALLOWED") {
          toast({
            title: "Falha no acesso",
            description: "Você não é administrador para restaurar usuários",
            duration: 5000,
            status: "warning"
          })
          history.replace("/");
        }
      })
  }


  const columns = [
    {
      name: "Nome",
      selector: (r) => r.nomeCompleto,
      sortable: true,
      compact: true,
      cell: (row) => (
        <Tooltip label={row.nomeCompleto} maxW="100vw">
          <Text isTruncated>{row.nomeCompleto}</Text>
        </Tooltip>
      )
    },
    {
      name: "Email",
      selector: (r) => r.email,
      compact: true,
      sortable: true,
      cell: (row) => (
        <Tooltip label={row.email} maxW="100vw">
          <Text isTruncated>{row.email}</Text>
        </Tooltip>
      )
    },
    {
      name: "Telefone",
      selector: (r) => r.telefone,
      compact: true,
      sortable: true
    },
    {
      name: "Regional",
      selector: (r) => r.regional,
      compact: true,
      sortable: true
    },
    {
      name: "Unidade",
      selector: (r) => r.unidade,
      compact: true,
      sortable: true
    },
    {
      name: "Núcleo",
      selector: (r) => r.nucleo,
      compact: true,
      sortable: true
    },
    {
      name: "Ações",
      cell: (row) => (
        <Box>
          <Menu
            strategy='fixed'
            isLazy
          >
            <MenuButton>
              <Icon as={BiMenu} w="24px" h="24px" />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => { setOpenDetalhe(true); setUsuarioIdSelected(row.id) }}>
                Detalhes
              </MenuItem>
              <MenuItem onClick={() => restaurarUsuario(row.id)}>
                Restaurar usuario
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )
    }
  ]

  return (
    <>
      <Container maxW="6xl" py="4">
        <HStack justifyContent="flex-end" mb="2">
          <Button variant="gray-outline" onClick={() => history.replace('/usuarios')}>
            Voltar
          </Button>
        </HStack>
        <Card>
          <DataTable
            data={users}
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Usuários por página",
              rangeSeparatorText: "de"
            }}
            highlightOnHover
            progressComponent={
              <Center p="42px">
                <Spinner
                  w="120px"
                  h="120px"
                  color="gray.40"
                  thickness='4px'
                />
              </Center>
            }
            columns={columns}
            progressPending={loadList}
            noDataComponent={
              <Center p="8">
                <Text>Nenhum usuário encontrado.</Text>
              </Center>
            }
          />
        </Card>
      </Container>
      <ModalDetalheUsuarios usuarioId={usuarioIdSelected} open={openDetalhe} onClose={() => setOpenDetalhe(false)} />
    </>
  );
}

export default UsuariosExcluidos;