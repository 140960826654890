import { FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import cidadeEstado from '../../../json/cidadeEstado';

function InputFormCidade({ register, errors, uf, disabled }) {
    const [list, setList] = useState([]);

    useEffect(() => {
        if (uf) {
            const estado = cidadeEstado.estados.find(e => e.sigla === uf);
            setList(estado.cidades)
        }
    }, [uf])

    return (
        <FormControl isRequired isInvalid={errors !== undefined} isDisabled={disabled}>
            <FormLabel htmlFor="cidade" >Cidade</FormLabel>
            <Select {...register} id="cidade" >
                <option value="">Cidade...</option>
                {list.map((e, index) => (
                    <option key={index} value={e}>{e}</option>
                ))}
            </Select>
            {errors !== undefined && <FormErrorMessage>{errors.message}</FormErrorMessage>}
        </FormControl>
    );
}

export default InputFormCidade;