import { Button, Center, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai'
function UserSucesso() {
    return (
        <Center h="100vh" w="100%" flexDirection="column">
            <Icon as={AiOutlineCheckCircle} w="220px" h="220px" color="gray.80" />
            <Text fontSize="1.4rem" color="gray.80" py="4">Cadastro finalizado!</Text>
            <Button onClick={() => window.close()} variant="gray-outline">
                Fechar
            </Button>
        </Center>
    );
}

export default UserSucesso;