import { Button, Text, HStack, ListItem, UnorderedList, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import Api from '../../Api/Api';
import CModal from '../CModal/Index';

function ModalRemoverUsuario({
    open,
    onClose,
    updateLista,
    usuarioId
}) {
    const [load, setLoad] = useState(false);
    const toast = useToast();
    function deletar() {
        setLoad(true)
        Api.get(`/home/DeletarUsuario/${usuarioId}`)
            .then(res => {
                toast({
                    title: "Usuario Removido com sucesso",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                setLoad(false)
                updateLista();
                onClose()
            })
            .catch(err => {
                console.log({ [`home/DeletarUsuario/${usuarioId}(catch)`]: err.response.data });
                setLoad(false)
            })
    }

    return (
        <CModal
            open={open}
            onClose={onClose}
            title="Excluir usuário"
            footer={
                <HStack>
                    <Button onClick={onClose} variant="gray-outline">
                        Cancelar
                    </Button>
                    <Button variant="gray" isLoading={load} onClick={() => deletar()}>
                        Remover usuário
                    </Button>
                </HStack>
            }
        >
            <Text mb="3">Removendo no highschool o considera como usuario excluido, deixando impossibilidado de:</Text>
            <UnorderedList mb="3">
                <ListItem>Entrar na plataforma</ListItem>
                <ListItem>Não estar mais em nenhum lugar na ranking</ListItem>
                <ListItem>Desconsiderado nos gráficos de leads e de usuários</ListItem>
                <ListItem>Desconsiderado os leads coletados por esse usuário</ListItem>
            </UnorderedList>
            <Text>Deseja remover esse usuário mesmo assim?</Text>
        </CModal>
    );
}

export default ModalRemoverUsuario;