import { Button } from '@chakra-ui/react';
import React from 'react';
import { baseUrl } from '../../Api/Api';

function ExportarModelo() {
    // const cssExport = {
    //     data: [],
    //     headers: [
    //         {label: "EmailColaborador", key: ""},
    //         {label: "DataHora", key: ""},
    //         {label: "Nome", key: ""},
    //         {label: "Email", key: ""},
    //         {label: "CPF", key: ""},
    //         {label: "Telefone", key: ""},
    //         {label: "Colegio", key: ""},
    //         {label: "Serie", key: ""},
    //         {label: "TipoInstituicao", key: ""},
    //         {label: "Sexo", key: ""},
    //         {label: "UF", key: ""},
    //         {label: "Cidade", key: ""},
    //         {label: "Fonte", key: ""},
    //         {label: "TipoProcesso", key: ""},
    //         {label: "Mensagem", key: ""},
    //         {label: "Unidade", key: ""},
    //         {label: "IES", key: ""},
    //         {label: "Assunto", key: ""},
    //         {label: "Curso", key: ""},
    //         {label: "TipoCurso", key: ""},
    //         {label: "MetodoCurso", key: ""},
    //         {label: "OrigemInteresse", key: ""},
    //         {label: "CanalOrigem", key: ""},
    //         {label: "TipoOrigem", key: ""},
    //         {label: "Marca", key: ""},
    //         {label: "Status", key: ""},
    //     ],
    //     filename: "Modelo.xlsx"
    // }    


    function baixarModelo() {
        window.open(baseUrl + "Formulario/DownloadModelo", "_blank");
    }

    return (
        // <CSVLink {...cssExport} className='button_style ml-2'>
        //     Baixar modelo
        // </CSVLink>
        <Button
            onClick={() => baixarModelo()}
            variant="dark"
            size="sm"
        >
            Baixar Modelo
        </Button>
    );
}

export default ExportarModelo;