import { Box, Badge, Button, Center, Container, Flex, HStack, Icon, Input, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, Tooltip, useToast } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Api from '../Api/Api';
import Card from '../components/Card/Card';
import ModalCriarEscola from '../components/ModalCriarEscola/Index';
import { BiMenu } from 'react-icons/bi'
import CModal from '../components/CModal/Index';
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import ModalEditarEscola from '../components/ModalEditarEscola/Index';
function ListaSuspensa() {
    const [escolas, setEscolas] = useState([]);
    const [loadEscolas, setLoadEscolas] = useState();
    const [openModalCriarEscola, setOpenModalCriarEscola] = useState(false);
    const [filterEscolaText, setFilterEscolaText] = useState("");
    const [idEscolaSelect, setIdEscolaSelect] = useState(null);
    const [loadExcluirEscola, setLoadExcluirEscola] = useState(false);
    const [openModalExcluirEscola, setOpenModalExcluirEscola] = useState(false);
    const [openRestaurarEscola, setOpenRestaurarEscola] = useState(false);

    //Editar Escola
    const [escolaEditar, setEscolaEditar] = useState(null);
    const [openEscolaEditar, setOpenEscolaEditar] = useState(false);
    //

    const filterEscolas = escolas.filter(e =>
        e.nome.toUpperCase().includes(filterEscolaText.toUpperCase()) ||
        e.ies.toUpperCase().includes(filterEscolaText.toUpperCase())
    )
    const toast = useToast();
    const history = useHistory();
    function removerEscola() {
        setLoadExcluirEscola(true)
        Api.post(`escola/Excluir/${idEscolaSelect}`)
            .then(() => {
                toast({
                    title: "Escola excluida com sucesso",
                    status: "success",
                    isClosable: true,
                    duration: 5000,
                })
                setLoadExcluirEscola(false)
                setOpenModalExcluirEscola(false)
                listarEscolas();
            })
            .catch(err => {
                setLoadExcluirEscola(false)
                console.log({ [`escola/Excluir/${idEscolaSelect}`]: err.response.data });
            })
    }

    function restaurarEscola(id) {
        setOpenRestaurarEscola(true)
        Api.post(`Escola/Restaurar/${id}`)
            .then(() => {
                toast({
                    title: "Escola restaurada",
                    status: 'success',
                    isClosable: true,
                    duration: 5000
                })
                setOpenRestaurarEscola(false);
                listarEscolas()
            })
            .catch(err => {
                setOpenRestaurarEscola(false);
                console.log({ [`Escola/Restaurar/${id}`]: err.response.data });
            })
    }

    const listarEscolas = useCallback(() => {
        setLoadEscolas(true)
        Api.get("Escola/ListAll")
            .then(res => {
                setEscolas(res.data)
                setLoadEscolas(false)
            })
            .catch(err => {
                setLoadEscolas(false)
                console.log({ "Escola/ListAll": err.response.data });
                if (err.response.data === "USER_NOT_ALLOWED") {
                    history.replace("/")
                    toast({
                        title: "Falha no acesso",
                        description: "Você não é administrador para acessar essa página",
                        duration: 5000,
                        status: "warning"
                    })
                }
            })
    }, [history, toast])

    useEffect(() => {
        listarEscolas();
    }, [listarEscolas])


    return (
        <div className='bg py-5'>
            <Container maxW='5xl' py="4">
                <Button mb="4" onClick={() => history.goBack()} variant="gray-outline">
                    Voltar
                </Button>
                <Card>
                    <Flex mb="4" justifyContent="space-between">
                        <Text fontSize="1.3rem" fontWeight="bold">Escolas</Text>
                        <HStack w="50%" spacing={4}>
                            <Input

                                value={filterEscolaText}
                                onChange={(e) => setFilterEscolaText(e.target.value)}
                                placeholder="Pesquisar nome ou Unidade..."
                            />
                            <Box>
                                <Button variant="dark" onClick={() => setOpenModalCriarEscola(true)}>
                                    Nova escola
                                </Button>
                            </Box>
                        </HStack>
                    </Flex>
                    <DataTable
                        pagination
                        highlightOnHover
                        fixedHeaderScrollHeight='40vh'
                        fixedHeader
                        paginationComponentOptions={{
                            rowsPerPageText: "Escolas por página",
                            rangeSeparatorText: "de"
                        }}
                        progressPending={loadEscolas}
                        progressComponent={
                            <Center p="42px">
                                <Spinner
                                    w="120px"
                                    h="120px"
                                    color="gray.40"
                                    thickness='4px'
                                />
                            </Center>
                        }
                        columns={[
                            {
                                name: "Nome",
                                compact: true,
                                selector: (row) => row.nome,
                                cell: (row) => (
                                    <Tooltip maxW="100vw" label={row.nome}>
                                        <Text isTruncated>{row.nome}</Text>
                                    </Tooltip>
                                )
                            },
                            {
                                name: "Unidade",
                                compact: true,
                                selector: (row) => row.ies
                            },
                            {
                                name: "status",
                                compact: true,
                                selector: (row) => row.excluido,
                                cell: (row) => row?.excluido ? (
                                    <Badge colorScheme="red">
                                        Excluida {moment(row.excluido).fromNow()}
                                    </Badge>
                                ) : (
                                    <Badge colorScheme="green">
                                        Ativa
                                    </Badge>
                                )

                            },
                            {
                                name: "Ações",
                                compact: true,
                                width: "80px",
                                cell: (row) => (
                                    <Menu strategy='fixed'>
                                        <MenuButton>
                                            <Icon as={BiMenu} />
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem onClick={() => {
                                                setEscolaEditar(row)
                                                setOpenEscolaEditar(true)
                                            }}>
                                                Editar
                                            </MenuItem>
                                            {
                                                !row.excluido && (
                                                    <MenuItem onClick={() => {
                                                        setIdEscolaSelect(row.id)
                                                        setOpenModalExcluirEscola(true)
                                                    }}>
                                                        Excluir
                                                    </MenuItem>
                                                )
                                            }
                                            {
                                                row.excluido && (
                                                    <MenuItem isDisabled={openRestaurarEscola} onClick={() => restaurarEscola(row.id)} >
                                                        Restaurar
                                                    </MenuItem>
                                                )
                                            }
                                        </MenuList>
                                    </Menu>
                                )
                            }
                        ]}
                        noDataComponent={
                            <Center p="8">
                                <Text>Nenhuma Escola encontrada.</Text>
                            </Center>
                        }
                        data={filterEscolas}
                    />
                </Card>
            </Container>
            <ModalCriarEscola open={openModalCriarEscola} onClose={() => setOpenModalCriarEscola(false)} />
            <CModal
                open={openModalExcluirEscola}
                onClose={() => setOpenModalExcluirEscola(false)}
                title="Remover escola"
                footer={
                    <HStack spacing={4}>
                        <Button onClick={() => setOpenModalExcluirEscola(false)}>
                            Cancelar
                        </Button>
                        <Button variant='gray' isLoading={loadExcluirEscola} onClick={() => removerEscola()}>
                            Remover Escola
                        </Button>
                    </HStack>
                }
            >
                <Text>Excluindo a escola ela será considerada como escola excluida, não sendo mais exibida em nenhum outro lugar, remover mesmo assim?</Text>
            </CModal>
            <ModalEditarEscola escola={escolaEditar} open={openEscolaEditar} onClose={() => {
                setOpenEscolaEditar(false);
                setEscolaEditar(null)
            }}
                updateList={listarEscolas}
            />
        </div>
    );
}

export default ListaSuspensa;