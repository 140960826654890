import React, { useState } from 'react';
import { useHistory } from 'react-router';
import logo from '../assets/logonew.png'
import { Box, Button, Center, Container, HStack, Image, Stack, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import Api from '../Api/Api';
import InputForm from '../components/Input/InputForm/Index'

const chreme = yup.object({
    email: yup.string().email("Email inválido.").required("Preenche o seu email."),
    password: yup.string().required("Preenche com a sua senha.")
})

function Login() {
    document.title = "Login"
    const { register, handleSubmit, formState: { errors }, setError } = useForm({
        resolver: yupResolver(chreme)
    });
    const [load, setLoad] = useState(false);
    const history = useHistory()
    const toast = useToast();
    function logar(data) {
        data.plataforma = "WEB"
        setLoad(true);
        Api.post('/Auth/SignIn', data)
            .then(res => {
                window.localStorage.setItem("@HSCHOOL_TOKEN", res.data.token)
                window.localStorage.setItem("@HSCHOOL_EXP", res.data.exp)
                window.localStorage.setItem("@HSCHOOL_ROLE", res.data.role)
                Api.defaults.headers.common["Authentication"] = 'Bearer ' + res.data.token;
                history.push("/")
            })
            .catch(err => {
                setLoad(false)
                console.log({ '/Auth/SignIn(catch)': err.response.data })
                if (err.response?.data === 'INVALID_PASSWORD')
                    setError("senha", { message: "Senha Inválida." })

                if (err.response?.data === "USER_NOT_FOUND")
                    setError("email", { message: "Usuário com esse email não encontrado." })

                if (err.response?.data === "USER_DELETED")
                    toast({
                        title: "Usuário deletado",
                        description: "Você não tem mais permissão de entrar na plataforma porque foi deletado(a) no sistema.",
                        isClosable: true
                    })
            })
    }

    return (
        <Box bg="#4f6170" w="100%" h="100vh" >
            <Container maxW="2xl" h="100vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    bg="white"
                    borderRadius="42px"
                    boxShadow="0px 0px 12px 8px #00000030"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p="8"
                    py="16"
                >
                    <Box w="100%">
                        <Center mb="4" >
                            <Image src={logo} w="75%" />
                        </Center>
                        <form onSubmit={handleSubmit(logar)}>
                            <Stack spacing={4}>
                                <InputForm register={register("email")} errors={errors.email}
                                    name="email" required title="Email" type="email"
                                />
                                <InputForm register={register("password")} errors={errors.senha}
                                    name="password" required title="Senha" type="password"
                                />
                            </Stack>

                            <HStack justifyContent="space-between">
                                <Button variant="gray-outline" onClick={() => history.push("/EsqueciSenha")} >
                                    Esqueci senha
                                </Button>
                                <Button type="submit" isLoading={load}
                                    variant="gray" size="lg"
                                >
                                    Entrar
                                </Button>
                            </HStack>
                        </form>
                    </Box>
                </Box>
            </Container>


        </Box>
    );
}

export default Login;