import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Center, Container, HStack, Icon, Menu, MenuButton, MenuItem, MenuList, Spinner, Tag, Text, Tooltip, useToast } from '@chakra-ui/react'
import Card from '../components/Card/Card';
import DataTable from 'react-data-table-component';
import Api from '../Api/Api';
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import 'moment/locale/pt-br'
import ViewQrcode from '../components/ViewQrcode/Index';
import PrintQrcode from '../components/PrintQrcode/Index';
import { BiMenu } from 'react-icons/bi'
import ModalRemoverQRcode from '../components/ModalRemoverQRcode/Index';
moment().locale('pt-BR');
function Qrcodes() {
    document.title = "qrcodes"
    const [list, setList] = useState([])
    const [load, setLoad] = useState(true)
    const [viewSelect, setViewSelect] = useState(null);
    const [openView, setOpenView] = useState(false);
    const [openRemover, setOpenRemover] = useState(false);
    const [loadRestaurar, setLoadRestaurar] = useState(false);
    const history = useHistory();
    const toast = useToast();

    const listQRcode = useCallback(() => {
        setLoad(true)
        Api.get('/qrcode')
            .then(res => {
                setList(res.data);
                setLoad(false)
            })
            .catch(err => {
                console.log({ "/qrcode/list(catch)": err.response.data });
                setLoad(false)
            })
    }, [])

    useEffect(() => {
        Api.get("Home/RoleUserLogado")
            .then(res => {
                localStorage.setItem("@HSCHOOL_ROLE", res.data)
                if(res.data === "Colaborador")
                        history.replace("/")
            })
            .catch(err => {

            })
    }, [])


    useEffect(() => {
        listQRcode()
    }, [listQRcode])

    const condicionalCol = [
        {
            when: row => row.encerrado !== null,
            style: { color: "#939393", backgroundColor: "#f8f8f8" }
        },
        {
            when: row => row.deletado !== null,
            style: { color: "#FC806F", backgroundColor: "#f8f8f8" }
        }
    ]

    function Restaurar(qrcodeId) {
        setLoadRestaurar(true);
        Api.get(`/qrcode/restaurar/${qrcodeId}`)
            .then(() => {
                toast({
                    title: "QR Code restaurado",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                })
                setLoadRestaurar(false)
                listQRcode()
            })
    }

    const columns = [
        {
            name: "ID",
            selector: (row) => row.id,
            conditionalCellStyles: condicionalCol,
            compact: true,
            cell: (row) => (
                <Tooltip label={row.id} maxW="100vw">
                    <Text isTruncated>{row.id}</Text>
                </Tooltip>
            )
        },
        {
            name: "IES",
            selector: (row) => row.ies,
            conditionalCellStyles: condicionalCol,
            compact: true,
            cell: (row) => (
                <Tooltip label={row.ies} maxW="100vw">
                    <Text isTruncated>{row.ies}</Text>
                </Tooltip>
            )
        },
        {
            name: "Unidade",
            selector: (row) => row.unidade,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.unidade} maxW="100vw">
                    <Text isTruncated>{row.unidade}</Text>
                </Tooltip>
            )
        },
        {
            name: "Tipo processo",
            selector: (row) => row.tipoProcesso,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.tipoProcesso} maxW="100vw">
                    <Text isTruncated>{row.tipoProcesso}</Text>
                </Tooltip>
            )
        },
        {
            name: "Mensagem",
            selector: (row) => row.mensagem,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.mensagem} maxW="100vw">
                    <Text isTruncated>{row.mensagem}</Text>
                </Tooltip>
            )
        },
        {
            name: "Fonte",
            selector: (row) => row.fonte,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.fonte} maxW="100vw">
                    <Text isTruncated>{row.fonte}</Text>
                </Tooltip>
            )
        },
        {
            name: "Cidade",
            selector: (row) => row.cidade,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.cidade}>
                    <Text isTruncated>{row.cidade}</Text>
                </Tooltip>
            )
        },
        {
            name: "UF",
            selector: (row) => row.uf,
            compact: true,
            conditionalCellStyles: condicionalCol,
            width: "64px"
        },
        {
            name: "Colégio",
            selector: (row) => row.escola,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.escola} maxW="100vw">
                    <Text isTruncated>{row.escola}</Text>
                </Tooltip>
            )
        },
        {
            name: "Tipo Instituição",
            selector: (row) => row.tipoInstituicao,
            compact: true,
            conditionalCellStyles: condicionalCol,
            width: "100px"
        },
        {
            name: "Avaliação da ação",
            selector: (row) => row.avaliacaoAcao,
            compact: true,
            conditionalCellStyles: condicionalCol,
            width: "100px",
            cell: (r) => r.avaliacaoAcao ? "Sim" : "Não"
        },
        {
            name: "Email responsável",
            selector: (row) => row.emailResponsavel,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Tooltip label={row.emailResponsavel} maxW="100vw">
                    <Text isTruncated>{row.emailResponsavel}</Text>
                </Tooltip>
            )
        },
        {
            name: "Data Ação",
            selector: (row) => row.createdAt,
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => moment(row.createdAt).format("DD/MM/YYYY HH:mm")
        },
        {
            name: "Encerramento",
            width: "auto",
            selector: (row) => row.encerrado,
            sortable: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => row.encerrado !== null ?
                <Tag size="sm" variant="outline" w="100%" whiteSpace="nowrap" >Encerrado {moment(row.encerrado).fromNow()}</Tag> :
                <Tag size="sm" variant="solid">Ativo</Tag>
        },
        {
            name: "Status",
            width: "auto",
            selector: (row) => row.deletado,
            sortable: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => row.deletado !== null ?
                <Tag size="sm" variant="outline" w="100%" whiteSpace="nowrap" >Deletado {moment(row.deletado).fromNow()}</Tag> :
                <Tag size="sm" variant="solid">Ativo</Tag>
        },
        {
            name: "Ações",
            compact: true,
            conditionalCellStyles: condicionalCol,
            cell: (row) => (
                <Box>
                    <Menu
                        strategy='fixed'
                        isLazy
                    >
                        <MenuButton>
                            <Icon as={BiMenu} w="24px" h="24px" />
                        </MenuButton>
                        <MenuList>
                            {/* <ViewQrcode item={row} /> */}
                            <MenuItem
                                onClick={() => {
                                    setViewSelect(row)
                                    setOpenView(true)
                                }}>Ver</MenuItem>
                            {
                                (!row.deletado && localStorage.getItem("@HSCHOOL_ROLE") === "Administrador") && (
                                    <MenuItem onClick={() => {
                                        setViewSelect(row)
                                        setOpenRemover(true)
                                    }}>
                                        Remover
                                    </MenuItem>
                                )
                            }
                            {
                                row.deletado && (
                                    <MenuItem isDisabled={loadRestaurar} onClick={() => Restaurar(row.id)}>
                                        Restaurar
                                    </MenuItem>
                                )
                            }
                            <PrintQrcode item={row} />
                            <MenuItem onClick={() => history.push(`/qrcode/detalhes`, {
                                QRCodeId: row.id
                            })}>
                                Listar Leads
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            )
        }
    ]

    return (
        <Container maxW={"8xl"} pt="80px" minH="calc(100vh - 80px)" >
            <Card>
                <HStack spacing={4} justifyContent="flex-end" mb="3">
                    <Button onClick={() => history.replace("")} variant="gray-outline">
                        Voltar
                    </Button>
                    <Button variant="dark" onClick={() => history.push("qrcode/criar")}>
                        Gerar novo Qrcode
                    </Button>
                </HStack>

                <DataTable
                    columns={columns}
                    data={list}
                    pagination
                    paginationComponentOptions={{
                        rowsPerPageText: "Qrcodes por página",
                        rangeSeparatorText: "de"
                    }}
                    highlightOnHover
                    customStyles={{
                        cells: {
                            style: {
                                fontSize: "12px",
                                fontWeight: "bold"
                            }
                        }
                    }}
                    progressPending={load}
                    progressComponent={
                        <Center p="42px">
                            <Spinner
                                w="120px"
                                h="120px"
                                color="gray.40"
                                thickness='4px'
                            />
                        </Center>
                    }
                    noDataComponent={
                        <Center p="8">
                            <Text>Nenhum Qrcode encontrado.</Text>
                        </Center>
                    }
                />
            </Card>
            <ViewQrcode item={viewSelect} open={openView} onClose={() => { setOpenView(false); setViewSelect(null) }} />
            <ModalRemoverQRcode
                qrcodeItem={viewSelect}
                open={openRemover}
                onClose={() => {
                    setViewSelect(null)
                    setOpenRemover(false)
                }}
                updateList={listQRcode}
            />
        </Container>
    );
}

export default Qrcodes;