import { Box, Button, Center, Checkbox, Container, Grid, GridItem, HStack, Stack, useToast } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InputCampus from '../components/Input/InputForm/InputCampus';
import InputIES from '../components/Input/InputForm/InputIES';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import InputForm from '../components/Input/InputForm/Index';
import InputformSelect from '../components/Input/InputForm/InputFormSelect';
import InputFormUF from '../components/Input/InputForm/InputFormUF';
import InputFormCidade from '../components/Input/InputForm/InputFormCidade';
import InputFormEscola from '../components/Input/InputForm/InputFormEscola';
import { useHistory } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import Api from '../Api/Api';
import moment from 'moment';
import CriptografoService from '../service/Criptografo';

const schema = yup.object({
    ies: yup.string().required("Selecione um ies."),
    unidade: yup.string().required("Selecione uma unidade."),
    mensagem: yup.string(),
    fonte: yup.string(),
    tipoProcesso: yup.string().required("Selecione um tipo de processo."),
    uf: yup.string().required("Selecione uma região."),
    cidade: yup.string().required("Selecione uma cidade."),
    colegio: yup.string().required("Selecione uma escola."),
    avaliacaoAcao: yup.bool(),
    tipoInstituicao: yup.string().notRequired().when("colegio", {
        is: (e) => e !== "OUTROS",
        then: yup.string().required("Selecione um tipo instituição.")
    }),
    emailResponsavel: yup.string().email("Email inválido.").required("Selecione um email responsável."),
    dataAcao: yup.string()
})

function CriarQrcode() {
    document.title = "Novo QR Code"
    const {
        register,
        handleSubmit,
        formState: { errors, },
        resetField,
        watch,
        setValue,
        setError
    } = useForm({
        resolver: yupResolver(schema)
    });
    const [resultQrcode, setResultQrcode] = useState("");
    const history = useHistory();
    const toast = useToast();
    const [load, setLoad] = useState(false);
    const [newQRCodeID, setNewQRCodeId] = useState(null);
    const [loadDesfazer, setLoadDesfazer] = useState(false);
    const role = localStorage.getItem("@HSCHOOL_ROLE");
    useEffect(() => {
        if (role === "Colaborador") {
            Api.get("QRcode/ClienteGetEmail")
                .then(res => {
                    setValue("emailResponsavel", res.data)
                })
        }
    }, [role, setValue])

    useEffect(() => {
        resetField("tipoInstituicao")
    }, [watch("colegio")])


    function gerarQrCode(data) {
        setLoad(true)
        // console.log({ "QRcode(request)": data })
        Api.post('/Qrcode', data)
            .then((res) => {
                setNewQRCodeId(res.data.id)
                const reqToken = { emailResponsavel: data.emailResponsavel }
                Api.post("QRcode/GenerateTokenQRCode", reqToken)
                    .then(resToken => {
                        const upData = { ...res.data, tokenQRCode: resToken.data }
                        const newRoute = CriptografoService("_HFencryptS#2022").formatarParaURL({
                            id: res.data.id,
                            token: resToken.data,
                            isCadastro: true
                        })
                        // const cryptdata = CriptografoService("_HFencryptS#2022").criptografar(upData);

                        // const newRoute = CriptografoService("_HFencryptS#2022").formatarParaURL({
                        //     crypt: cryptdata,
                        //     isCadastro: true
                        // })
                        setResultQrcode(newRoute);
                        toast({
                            title: "Dados salvos com sucesso!",
                            status: "success",
                            duration: "5000",
                            isClosable: true
                        })
                        setLoad(false)
                    })
                    .catch(err => {
                        console.log({ "QRcode/GenerateTokenQRCode(catch)": err.response.data })
                    })


            })
            .catch(err => {
                console.log({ '/Qrcode(post)(catch)': err.response.data });
                setLoad(false)
                if (err.response.data === "USUARIO_RESPONSAVEL_NOT_FOUND") {
                    setError("emailResponsavel", { message: "Email não encontrado na plataforma." });
                    setResultQrcode("");
                }
            })
    }


    //Desfazer QRCode
    function deletarQrcode() {
        setLoadDesfazer(true)
        Api.get(`/QRcode/Delete/${newQRCodeID}`)
            .then(() => {
                setResultQrcode("");
                setLoadDesfazer(false)
                setNewQRCodeId(null);
            })
            .catch(err => {
                setLoadDesfazer(false)
                console.log({ [`/QRcode/Delete/${newQRCodeID}`]: err.response });
            })
    }

    //Temporizador, indicando a dataAção da geração/QRCode
    useEffect(() => {
        if (resultQrcode === "") {
            let timer;
            setValue("dataAcao", moment().format('YYYY-MM-DDTHH:mm:ss'));
            timer = setInterval(() => {
                setValue("dataAcao", moment().format('YYYY-MM-DDTHH:mm:ss'));
            }, 1000)
            return () => clearInterval(timer);
        }
    }, [resultQrcode, setValue])


    return (
        <Container maxW="5xl" minH='100vh'>
            <Grid templateColumns="1fr 1fr" >
                <GridItem pt="80px" pb="80px">
                    <form onSubmit={handleSubmit(gerarQrCode)}>
                        <Stack spacing={4} >
                            <InputIES register={register("ies")} errors={errors.ies}
                                disabled={resultQrcode !== ""}
                            />

                            <InputCampus reset={resetField} marca={watch('ies', false)}
                                register={register("unidade")}
                                errors={errors.unidade}
                                disabled={resultQrcode !== ""}
                            />

                            <InputformSelect register={register("tipoProcesso")} errors={errors.tipoProcesso}
                                name="tipoProcesso" placeholder="Selecione o tipo do processo..." required
                                typeList="tipoProcesso" title="Tipo Processo"
                                disabled={resultQrcode !== ""}
                            />

                            <InputForm register={register("mensagem")} errors={errors.mensagem}
                                name="mensagem" title="Mensagem" disabled={resultQrcode !== ""}
                            />

                            <InputForm register={register("fonte")} errors={errors.fonte}
                                name="fonte" title="Fonte" disabled={resultQrcode !== ""}
                            />

                            <InputFormUF register={register("uf")} errors={errors.uf}
                                disabled={resultQrcode !== ""}
                            />

                            <InputFormCidade register={register("cidade")} errors={errors.cidade}
                                uf={watch("uf", false)} disabled={resultQrcode !== ""}
                            />

                            <InputFormEscola register={register("colegio")}
                                // watchUnidade={watch("unidade", false)}
                                errors={errors.colegio}
                                setValues={setValue}
                                // resetEscola={() => resetField("colegio")}
                                // unidade={
                                //     (watch("ies", false) === "ESTACIO" || watch("ies", false) === "IDOMED") ?
                                //         watch("ies") : watch("unidade")
                                // }
                                disabled={resultQrcode !== ""}
                            />

                            <InputformSelect register={register("tipoInstituicao")} errors={errors.tipoInstituicao}
                                name="tipoInstituicao" required title="Tipo Instituição" typeList="instituicao"
                                placeholder="Tipo Instituição..." disabled={resultQrcode !== "" || watch("colegio") === "OUTROS"}
                            />

                            {
                                role === "Administrador" ? (
                                    <InputForm register={register("emailResponsavel")} errors={errors.emailResponsavel}
                                        name="emailResponsavel" required title="Email responsável" type='email'
                                        disabled={resultQrcode !== ""}
                                    />
                                ) : (
                                    <InputForm register={register("emailResponsavel")} errors={errors.emailResponsavel}
                                        name="emailResponsavel" required title="Email responsável" type='email'
                                        disabled
                                    />
                                )
                            }

                            <Checkbox {...register("avaliacaoAcao")} isDisabled={resultQrcode !== ""}>
                                Qual sua opinião sobre o evento?
                            </Checkbox>

                            <InputForm register={register("dataAcao")} errors={errors.dataAcao}
                                name="dataAcao" disabled required title="Data Ação" type="datetime-local"
                            />

                            <HStack spacing={4} >
                                {
                                    resultQrcode === "" && (
                                        <>
                                            <Button variant="gray-outline" onClick={() => history.goBack()}>
                                                Voltar
                                            </Button>
                                            <Button variant="dark" type="submit" isLoading={load}>
                                                Gerar Qr code
                                            </Button>
                                        </>
                                    )
                                }
                            </HStack>
                        </Stack>
                    </form>
                </GridItem>
                <GridItem
                    position="relative" w="100%" h="100vh" display="flex" justifyContent="center" alignItems="center" >
                    <Box position="fixed" >
                        <Box>
                            {resultQrcode === "" &&
                                <QRCodeCanvas size='380' value="" bgColor="transparent" fgColor="#dfdfdf" />
                            }

                            {resultQrcode !== "" && (
                                <Center flexDirection="column">
                                    <Box>
                                        <QRCodeCanvas size='380' value={resultQrcode} onClick={() => window.open(resultQrcode, "_blank")} bgColor="transparent" />
                                        <HStack spacing={4} mt="4">
                                            <Button isLoading={loadDesfazer} variant="gray-outline" onClick={() => deletarQrcode()} >
                                                Desfazer
                                            </Button>
                                            <Button
                                                variant="gray" mt="4"
                                                onClick={() => history.push("/qrcode")}>
                                                Voltar para a lista QRcodes
                                            </Button>
                                        </HStack>
                                    </Box>
                                </Center>
                            )}
                        </Box>
                    </Box>
                </GridItem>
            </Grid>
        </Container>
    );
}

export default CriarQrcode;